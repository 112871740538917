import { colors } from "src/styles/theme";
import { styled } from "styled-components";
import Button from "../Button";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { useRecoilState } from "recoil";
import { recoilStates } from "src/recoil/recoilStates";
import { bookingDummys } from "src/services/dummys";
import Stepper from "../Stepper";

export default function WaitYearBookingProposal() {
  const navigate = useNavigate();
  // const [_, setYearBooking] = useRecoilState(recoilStates.yearBooking);
  const [bookingApplication] = useRecoilState(recoilStates.bookingApplication);

  return (
    <Container className="d-flex fd-c gap-2">
      <Stepper
        className="page-pd-negative-side page-pd-negative-top"
        currentIdx={1}
        title="( 골프장 ) 조건 검토 중~"
        steps={[
          { label: <>단체등록</> },
          { label: "부킹 신청" },
          { label: "조건 협의" },
          { label: "부킹 확정" },
          {
            label: (
              <div className="d-flex fd-c ai-c">
                <div>보증금</div>
                <div>확인</div>
              </div>
            ),
          },
          { label: "멤버 등록" },
        ]}
      />
      <div className="msg-box d-flex gap-3 fd-c jc-c ai-c pd-2">
        <p className="msg">
          연간 부킹 신청 접수가 완료 되었습니다. 담당자 확인 후 바로 연락드리겠습니다
        </p>
        <p className="date">접수일시: {bookingApplication?.created_at}</p>
      </div>
      <Button
        $colorTheme="lightGreen"
        onClick={() => navigate("/year-booking/group-register/result")}
        $round={true}
        $buttonSize="md"
      >
        연간 부킹 신청 내역 확인
      </Button>
    </Container>
  );
}

const Container = styled.div`
  header {
    font-size: 16px;
    font-weight: bold;
    .left {
      color: ${colors.green.second};
    }
    .right {
      color: ${colors.gray.second};
    }
  }

  .msg-box {
    min-height: 200px;

    .msg {
      /* font-weight: bold; */
      font-size: 18px;
    }
    .date {
    }
  }
`;
