import { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { selector, useRecoilState, useRecoilValue } from "recoil";
import { Button, Loading, MainPageButtons, usePopupPage } from "src/components";
import { BookingConditionContents } from "src/components/PopupPageContents";
import {
  HasProposal,
  HasYearBookingContent,
  // NoBookingContent,
  NoYearGroupContent,
  PaymentWating,
  WaitYearBookingProposal,
} from "src/components/YearBooking";
import { recoilStates } from "src/recoil/recoilStates";
import { colors } from "src/styles/theme";
import styled, { css } from "styled-components";

export default function YearBooking() {
  const [group] = useRecoilState(recoilStates.group);
  const hasGroup = Boolean(group.id);
  const [yearBooking] = useRecoilState(recoilStates.yearBooking);
  const [init] = useRecoilState(recoilStates.init);

  const [bookingApplication] = useRecoilState(recoilStates.bookingApplication);

  const navigate = useNavigate();
  const isRejectedByAllClubs = useMemo(() => {
    return bookingApplication?.clubRels?.every((cr) => [7].includes(cr.year_booking_status_id));
  }, [bookingApplication]);

  const { PopupComponent: BookingConditionPopuPage, openPopup: openBookingCondition } =
    usePopupPage("bookingCondition");

  function 기존기획() {
    return (() => {
      if (
        !group.id ||
        !bookingApplication ||
        bookingApplication?.clubRels?.every((cr) =>
          [2, 5, 7, 8].includes(cr.year_booking_status_id)
        ) // 이전 신청건에 대해 모든 골프장에 거절(7) 혹은 계약만료(8) 혹은 미선정(5)
      ) {
        // 그룹생선 전 혹은 부킹 신청 전
        return (
          <>
            <NoYearGroupContent isRejectedByAllClubs={isRejectedByAllClubs} />
            <MainPageButtons />
          </>
        );
      }
      if (bookingApplication?.clubRels?.some((cr) => cr.year_booking_status_id === 6)) {
        // 입금완료확인
        return (
          <>
            <HasYearBookingContent />
            <Button
              className="mt-2"
              $colorTheme="lightGreen"
              $buttonSize="md"
              $round
              onClick={() => {
                navigate("/year-booking/team-register");
              }}
            >
              팀배정
            </Button>
            <Button
              className="mt-2"
              $buttonSize="md"
              $colorTheme="black"
              $round
              onClick={openBookingCondition}
            >
              부킹 조건 확인
            </Button>
            <BookingConditionPopuPage
              headerMsg="부킹 조건 확인"
              type="back"
              content={
                <>
                  <BookingConditionContents />
                </>
              }
            />
            <MainPageButtons />
          </>
        );
      }
      if (
        bookingApplication?.clubRels?.some((cr) => cr.year_booking_status_id === 4)
        //보증금 입금대기(사용자 확정)
      ) {
        return (
          <>
            <PaymentWating />
            <Button $buttonSize="md" $colorTheme="lightGreen" $round onClick={openBookingCondition}>
              부킹 조건 확인
            </Button>
            <BookingConditionPopuPage
              headerMsg="부킹 조건 확인"
              type="back"
              content={
                <>
                  <BookingConditionContents />
                </>
              }
            />
            <MainPageButtons />
          </>
        );
      }
      // if (
      //   bookingApplication?.clubRels?.every((cr) => cr.year_booking_status_id === 1)
      //   // 부킹신청 직후(골프장 조건제시 이전)
      // ) {
      if (
        bookingApplication?.clubRels?.every((cr) => [1, 7].includes(cr.year_booking_status_id)) // 거절 혹은 조건제시 이전
        // 부킹신청 직후(골프장 조건제시 이전)
      ) {
        return (
          <>
            <WaitYearBookingProposal />
            <MainPageButtons />
          </>
        );
      }

      // 1개이상의 골프장이 조건제시시
      return (
        <>
          <HasProposal />
          <MainPageButtons />
        </>
      );
    })();
  }

  return (
    // display: flex;
    // align-items: center;
    // gap: 10px;
    <Main className="page-pd" $isUserDateFetchDone={init.isInit}>
      {/* {hasGroup && (
        <div className="group-top d-flex ai-c gap-2 pd-2">
          <div className="img-box">
            <img className="group-img" src={group.group_logo_url} />
          </div>
          <h3>{group.name}</h3>
        </div>
      )} */}
      {init.isInit ? 기존기획() : <Loading />}
    </Main>
  );
}

const Main = styled.main<{ $isUserDateFetchDone: boolean }>`
  flex: 1;
  display: flex;
  flex-direction: column;
  ${(p) =>
    !p.$isUserDateFetchDone &&
    css`
      align-items: center;
      justify-content: center;
    `}

  .group-top {
    /* padding: 0px 10px; */
    background-color: ${colors.gray.fourth};
    margin-top: -15px;
    margin-left: -15px;
    margin-right: -15px;

    .img-box,
    img {
      width: 50px;
      height: 50px;
      border-radius: 999px;
    }
    img {
      object-fit: cover;
    }

    .group-img {
    }
  }
`;
