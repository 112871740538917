import { useEffect, useState } from "react";
import { createTeeshotAxiosInstance } from "src/http";
import { District } from "src/types";
import { sleep } from "src/utils";


function useGroupLocation() {
  const [groupLocations, setGroupLocations] = useState<District[]>();
  useEffect(() => {
    (async () => {
      try {
        const res = await createTeeshotAxiosInstance().get("/common/district");
        setGroupLocations(
          (() => {
            const allCountryIdx = res.data.data.findIndex((d: any) => d.cd === 40);
            const [allCountry] = res.data.data.splice(allCountryIdx, 1) || [];
            if (allCountry) {
              return [allCountry, ...(res?.data?.data ?? [])];
            }
            return res.data.data;
          })() as District[]
        );
        if (res.data.success === false) {
          throw new Error();
        }
      } catch (e) {
        alert("지역정보 에러");
      }
    })();
  }, []);

  return groupLocations;
}

export default useGroupLocation;
