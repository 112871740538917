import React from "react";
import { useRecoilState } from "recoil";
import { recoilStates } from "src/recoil/recoilStates";
import styled from "styled-components";
import Label from "../Label";

function BookingConditionContents() {
  const [ba] = useRecoilState(recoilStates.bookingApplication);
  const cr = ba?.clubRels?.find((cr) => [4, 6].includes(cr.year_booking_status_id));
  
  if (!cr) {
    return <div>내역없음</div>;
  }
  return (
    <Container className="page-pd d-flex fd-c gap-2">
      <div>
        <Label content="클럽명" className="bold" />
        <div className="pd-1">{cr.name}</div>
      </div>
      <div>
        <Label content="부킹일정" className="bold" />
        <div className="pd-1">
          <div>
            {JSON.parse(cr.month_arr_json_str ?? "")
              ?.map((m: string) => `${m}월`)
              .join(", ")}
          </div>
          <div>
            {JSON.parse(cr.week_arr_json_str ?? "")
              ?.map((w: string) => `${w}주`)
              .join(", ")}
          </div>
          <div>{JSON.parse(cr.day_arr_json_str ?? "").join(", ")}</div>
        </div>
      </div>
      <div>
        <Label content="부킹일정 상세" className="bold" />
        <div className="pd-1">
          {cr.year_booking_round_dates?.map((rd) => {
            return (
              <div key={rd.id}>
                {new Date(rd.round_date).toLocaleDateString("kr-KR", { dateStyle: "full" })}{" "}
                {cr?.start_time
                  ? `${cr?.start_time?.slice(0, 2)}:${cr?.start_time?.slice(2, 4)}`
                  : ""}
              </div>
            );
          })}
        </div>
      </div>
      <div>
        <Label content="기타 안내사항" className="bold" />
        <div className="pd-1">{cr.etc_notification || "없음"}</div>
      </div>
      <div></div>
      <div></div>
      <div></div>
    </Container>
  );
}

export default BookingConditionContents;

const Container = styled.div``;
