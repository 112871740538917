import React, { useRef } from "react";
import { colors } from "src/styles/theme";
import Input from "../Input";
import { useEffect, useState } from "react";
import fetchClubs from "src/services/fetchClubs";
import Loading from "../Loading";
import { useDebounce, useInfiniteScroll } from "src/hooks";
import styled from "styled-components";
import Button from "../Button";

import { ClubType, District, SelectedClubType } from "src/types";
import { createTeeshotAxiosInstance } from "src/http";
import SreenCoverLoading from "../SreenCoverLoading";

export default function ClubFindPopupContents(props: {
  clubLocationId?: number;
  setAnnouncementShowingClub: React.Dispatch<React.SetStateAction<ClubType | undefined>>;
  openAnnounceAgree: () => void;
  groupLocations?: District[];
}) {
  // const [activeLcId, setActiveLcId] = useState<number>(40); // 40 - 전국구 disctrict cd
  const [clubs, setClubs] = useState<ClubType[]>();
  const [isScrollLoading, setIsScrollLoading] = useState(false);
  // TODO 아이템 추가 로딩 ui 는 리스트 아래에 로딩뜨도록 수정
  const [isNewLoading, setIsNewLoading] = useState(true);
  const { value: inputValue, onChange: onChangeInput } = Input.hooks.useInput();
  const { value: debouncedInputValue, debounceContinuing } = useDebounce(String(inputValue), 500);
  const listCount = 20;
  // const [page, setPage] = useState(1);
  const testVal = useRef("");
  const page = useRef(1);
  const activeLcId = useRef<number>(40); // 40 - 전국구 disctrict cd
  const [refreshFlag, setRefreshFlag] = useState(false);
  const { lastItemRef } = useInfiniteScroll(() => {
    (async () => {
      const params: any = {
        limit: listCount,
        offset: page.current * listCount,
        hasRecruitmentUrl: 1,
      };

      if (testVal.current?.length && testVal.current?.length > 0) {
        params.likename = `%${testVal.current}%`;
      } else {
        console.log("delete search :");
        delete params.likename;
      }
      if (activeLcId.current && activeLcId.current !== 40) {
        const locationName = props.groupLocations?.find((lc) => lc.cd === activeLcId.current)?.name;
        if (locationName) {
          params.likeregion = `%${locationName}%`;
        } else {
          delete params.likeregion;
        }
      }
      try {
        setIsScrollLoading(true);
        const res = await createTeeshotAxiosInstance().get("/yearBooking/club", {
          params,
        });
        setClubs((prev) => {
          return [...(prev ?? []), ...(res.data.data ?? [])];
        });
      } catch (e) {
        console.log("e :", e);
      } finally {
        page.current += 1;
        setIsScrollLoading(false);
      }
    })();
  });

  useEffect(() => {
    (async () => {
      try {
        const res = await createTeeshotAxiosInstance().get("/yearBooking/club", {
          params: { limit: 20, hasRecruitmentUrl: 1 },
        });
        setClubs(res.data.data);
      } catch (e) {
        console.log("e :", e);
      } finally {
        setIsNewLoading(false);
      }
    })();
  }, []);

  useEffect(() => {
    if (false) {
    } else {
      (async () => {
        testVal.current = debouncedInputValue;
        page.current = 1;
        try {
          setIsNewLoading(true);
          const params: any = {
            likename: `%${debouncedInputValue}%`,
            limit: listCount,
            hasRecruitmentUrl: 1,
          };
          if (activeLcId.current && activeLcId.current !== 40) {
            const locationName = props.groupLocations?.find(
              (lc) => lc.cd === activeLcId.current
            )?.name;
            if (locationName) {
              params.likeregion = `%${locationName}%`;
            } else {
              delete params.likeregion;
            }
          }
          const res = await createTeeshotAxiosInstance().get("/yearBooking/club", {
            params,
          });
          setClubs(res.data.data);
        } catch (e) {
          console.log("e :", e);
        } finally {
          setIsNewLoading(false);
        }
      })();
    }
  }, [debouncedInputValue, activeLcId.current]);
  return (
    <>
      {isScrollLoading && <SreenCoverLoading />}
      <InnerDiv className="page-pd flex-1 d-flex fd-c gap-2">
        <Input
          searchTheme
          attr={{ placeholder: "골프장 이름을 입력하세요.", onChange: onChangeInput }}
        />
        <div className="locations">
          {props.groupLocations?.map((lc) => {
            return (
              <div
                key={lc.name}
                className={`lc ${lc.cd === activeLcId.current ? "active" : ""}`}
                onClick={() => {
                  activeLcId.current = lc.cd;
                  setRefreshFlag((b) => !b);
                }}
              >
                {lc.name}
              </div>
            );
          })}
        </div>
        {isNewLoading ? (
          <>
            <div className="flex-1 d-flex jc-c ai-c">
              <Loading />
            </div>
          </>
        ) : (
          <>
            {!clubs?.length && <p className="no-result">검색결과가 없습니다.</p>}
            <StyledUl>
              {clubs?.map((c, idx) => {
                return (
                  <li
                    key={`club-${c.id}-${idx}`}
                    ref={lastItemRef as any}
                    onClick={() => {
                      props.openAnnounceAgree();
                      props.setAnnouncementShowingClub(c);
                    }}
                  >
                    <img className="thumbnail" src={c.thumbnail} alt="" />
                    <div className="li-inner">
                      <div className="name">{c.name}</div>
                      <div className="location">
                        {"("}
                        {c.region}
                        {")"}
                      </div>
                    </div>
                  </li>
                );
              })}
            </StyledUl>
          </>
        )}
      </InnerDiv>
    </>
  );
}

const InnerDiv = styled.div`
  height: 0;

  .locations {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    gap: 5px;
    .lc {
      justify-content: center;
      align-items: center;
      font-size: 14px;
      display: flex;
      background-color: ${colors.gray.second};
      border-radius: 5px;
      padding: 5px;
      &.active {
        background-color: ${colors.green.second};
      }
    }
  }
  .no-result {
    display: flex;
    height: 100px;
    align-items: center;
    justify-content: center;
    font-weight: bold;
  }
`;
const StyledUl = styled.ul`
  display: grid;
  grid-template-columns: 1fr 1fr;
  @media only screen and (min-width: 600px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
  gap: 10px;
  overflow-y: auto;
  min-height: 1px;
  li {
    display: flex;
    flex-direction: column;
    gap: 5px;
    cursor: pointer;
    img {
      width: 100%;
      height: 110px;
      object-fit: cover;
    }
    .li-inner {
      display: flex;
      flex-direction: column;
      align-items: center;
      .name {
        font-size: 18px;
      }
      .location {
        font-size: 16px;
        color: ${colors.gray.first};
      }
    }
  }
`;
