import { ComponentProps, ReactNode } from "react";
import { selector } from "recoil";
import { colors } from "src/styles/theme";
import styled, { css } from "styled-components";

const colorObj = {
  gray: {
    backGroundColor: colors.gray.second,
  },
  default: {
    backGroundColor: "white",
  },
};
type ColorThemeType = keyof typeof colorObj;
export default function ContentBox(props: {
  header?: ReactNode;
  content?: ReactNode;
  asBtn?: boolean;
  onClick?: () => void;
  isSelectedBtn?: boolean;
  colorTheme?: ColorThemeType;
  styleProps?: ComponentProps<typeof Box>["style"];
}) {
  return (
    <Box
      style={props.styleProps}
      className={props.isSelectedBtn ? "selected" : ""}
      $asBtn={props?.asBtn}
      $ColorTheme={props.colorTheme}
      onClick={() => {
        props?.onClick && props.onClick();
      }}
    >
      {props.header && <Header>{props.header}</Header>}
      <Content>{props.content}</Content>
    </Box>
  );
}

const Box = styled.div<{ $asBtn?: boolean; $ColorTheme?: ColorThemeType }>`
  /* flex: 1; */
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 180px;
  border: solid 1px black;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  padding: 10px;
  gap: 10px;
  background-color: ${(p) => colorObj?.[p.$ColorTheme ?? "default"].backGroundColor};
  ${(p) => {
    if (p?.$asBtn) {
      return css`
        background-color: ${colors.gray.third};
        &.selected {
          background-color: ${colors.gray.fourth};
          border: solid 3px ${colors.green.second};
        }
      `;
    }
  }};
`;
const Header = styled.header`
  display: flex;
  flex-direction: column;
  border: none;
  /* align-self: center; */
`;
const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;
