export const colors = {
  green: {
    first: "#0b7b4b",
    second: "#02c316",
  },
  black: {
    first: "#000000",
    second: "#424242",
  },
  gray: {
    first: "#818181",
    second: "#d9d9d9",
    third: "#ededed",
    fourth: "#f5f5f5",
  },
  purple: {
    first: "#6675ff",
  },
  red: {
    first: "#ff0000",
  },
  yellow: {
    first: "#ffe500",
  },
  blue: {
    first: "#0019ff",
    second: "#b0b8ff",
  },
};
