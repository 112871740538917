import { colors } from "src/styles/theme";
import styled from "styled-components";
import { IoSearchOutline } from "react-icons/io5";

export default function FindInput(props: {
  label?: string;
  required?: boolean;
  onClick?: () => void;
  selectedName?: string;
}) {
  return (
    <Container
      className="d-flex gap-1"
      onClick={() => {
        props.onClick && props.onClick();
      }}
    >
      <div className="lb d-flex jc-c ai-c">
        <span className="rq">{props.required ? "*" : ""}</span>
        {props.label}
      </div>
      <div className="input-box-type flex-1 custom-input-shape d-flex jc-sb ai-c pd-2 cursor-p">
        <div className="club-name">{props.selectedName}</div>
        <IoSearchOutline size={25} />
      </div>
    </Container>
  );
}

const Container = styled.div`
  .lb {
    flex-basis: 30px;
    background-color: ${colors.gray.second};

    .rq {
      color: red;
    }
  }
`;
