import React, { ReactNode } from "react";
import { colors } from "src/styles/theme";
import styled from "styled-components";

function Stepper(props: {
  className?: string;
  steps: {
    label?: ReactNode;
    nextLineType?: "dotted" | "solid";
    title?: string;
    isError?: boolean;
  }[];
  currentIdx?: number;
  currentStatus?: "done" | "error";
  title?: string;
}) {
  return (
    <Container className={props.className}>
      {props.title ? <h4>{props.title}</h4> : null}
      <div className="steps">
        {props.steps.map((st, idx) => {
          return (
            <Step
              key={`step-${idx}`}
              className={(() => {
                const classes = [];
                if (props.steps.length - 1 !== idx) {
                  classes.push("flex-1");
                }
                if (props.currentIdx === idx && !st.isError) {
                  classes.push("dotted");
                }
                if ((props.currentIdx ?? 9999) < idx) {
                  classes.push("inactive");
                }
                if (st.isError) {
                  classes.push("error");
                }
                return classes.join(" ");
              })()}
            >
              <div className="btn">
                <div className={`label-section`} key={`label-${idx}`}>
                  {st.label}
                </div>
              </div>
              {props.steps.length - 1 === idx ? null : (
                <>
                  <div className="line" />
                </>
              )}
            </Step>
          );
        })}
      </div>
    </Container>
  );
}

export default Stepper;

const Container = styled.div`
  padding: 20px;
  gap: 10px;
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: space-between;
  padding-bottom: 50px;
  color: white;
  h4 {
    display: flex;
    justify-content: center;
    font-size: 18px;
    padding: 5px;
  }
  .steps {
    display: flex;
  }
  background-color: #44546a;
`;

const Step = styled.div`
  display: flex;
  align-items: center;

  &.inactive {
    .btn {
      background-color: white;
    }
    .line {
      border-bottom-color: ${colors.gray.first};
    }
  }
  &.dotted {
    .line {
      border-bottom-style: dotted;
    }
  }
  &.error {
    .btn {
      background-color: red !important;
    }
    .line {
      border-bottom-color: ${colors.gray.first};
    }
  }
  .btn {
    background-color: ${colors.green.second};
    width: 25px;
    height: 25px;
    border-radius: 5px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    .label-section {
      font-size: 12px;
      display: flex;
      justify-content: center;
      width: 60px;
      position: absolute;
      left: 50%;
      bottom: -10px;
      transform: translate(-50%, 100%);
    }
  }

  .line {
    width: 100%;
    flex: 1;
    border-bottom-width: 4px;
    border-bottom-color: ${colors.green.second};
    border-bottom-style: solid;
  }
`;

const Line = styled.div``;
