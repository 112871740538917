import { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import {
  Button,
  DoubleButton,
  SreenCoverLoading,
  usePopupPage,
  usePopupSelect,
  useRadioBoxGroup,
} from "src/components";
import { recoilStates } from "src/recoil/recoilStates";
import styled from "styled-components";
import { TeamType } from "src/types";
import { MemberSearchPopupContents } from "src/components/PopupPageContents";
import { sleep } from "src/utils";
import { useNavigate } from "react-router-dom";
import { createTeeshotAxiosInstance } from "src/http";
import { TeamDataType } from "src/types";
export default function YearGroupTeamRegister() {
  const navigate = useNavigate();

  const [group, setGroup] = useRecoilState(recoilStates.group);

  const [bookingApplication, setBookingApplication] = useRecoilState(
    recoilStates.bookingApplication
  );
  const clubRelData = bookingApplication?.clubRels?.find((cr) => cr.year_booking_status_id === 6);
  const rounds = clubRelData?.year_booking_round_dates;

  const { PopupSelect: DatePopupSelect, activeIds: activeDateIds } = usePopupSelect({
    items:
      rounds?.map((rd) => ({
        id: rd.id,
        name: rd.round_date,
      })) ?? [],
    label: "부킹일자",
  });

  const [teams, setTeams] = useState<TeamDataType[]>();

  const [isOptionLoading, setIsOptionLoading] = useState(false);
  const [allOptions, setAllOptions] = useState<{ id: number; name: string }[]>();

  const [forcedRerenderFlag, setForcedRerenderFlag] = useState(true);

  useEffect(() => {
    setForcedRerenderFlag(false);
    setTimeout(() => {
      setForcedRerenderFlag(true);
    }, 0);
  }, [activeDateIds]);

  useEffect(() => {
    (async () => {
      try {
        setIsOptionLoading(true);
        const res = await createTeeshotAxiosInstance().get("/yearBooking/booking/roundOption");
        setAllOptions(res.data.data);
      } catch (e) {
        alert("옵션 리스트 불러오기 실패");
        console.log("e :", e);
      } finally {
        setIsOptionLoading(false);
      }
    })();
  }, []);

  const currentRound = rounds?.find((r) => r.id === activeDateIds?.[0]);
  const initialOptions = rounds
    ?.find((r) => r.id === activeDateIds?.[0])
    ?.round_options?.map((o) => o.etc_round_option_id);
  useEffect(() => {
    const roundData = rounds?.find((r) => r.id === activeDateIds?.[0]);

    if (!roundData?.teams || roundData?.teams?.length < 1) {
      setTeams([
        { team_number: 1, year_booking_round_date_team_rel_booking_group_member: [] },
        { team_number: 2, year_booking_round_date_team_rel_booking_group_member: [] },
      ]);
      return;
    }
    setTeams(JSON.parse(JSON.stringify(roundData.teams)) as any);
  }, [bookingApplication, activeDateIds[0]]);

  const {
    PopupComponent: MemeberSearchPopup,
    openPopup: openMemberSearchPopup,
    closePopup: closeMemberSearchPopup,
  } = usePopupPage("member-search");

  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const [currentSelectedTeamNumber, setCurrentSelectedTeamNumber] = useState(0);
  const [selectedOptionIds, setSelectedOptionIds] = useState<number[]>([]);

  const handleSubmit = async () => {
    const notEmptyAddingTeams = teams?.filter((t) => {
      return (
        (t.year_booking_round_date_team_rel_booking_group_member?.length &&
          t.year_booking_round_date_team_rel_booking_group_member?.length > 0) ||
        t.deleteMembers?.length
      );
    });

    if (!activeDateIds[0] || !notEmptyAddingTeams?.length) {
      alert("라운드 일자 및 팀 정보를 입력하세요");
      return;
    }
    try {
      setIsSubmitLoading(true);
      const teamSubmitRes = await createTeeshotAxiosInstance().post("/yearBooking/booking/team", {
        submitData: {
          roundId: activeDateIds[0],
          teams: notEmptyAddingTeams,
          roundOptionIds: selectedOptionIds,
        },
      });
      if (!teamSubmitRes.data.success) {
        throw new Error();
      }
      setBookingApplication((prev) => {
        if (!prev) {
          return null;
        }
        return {
          ...prev,
          clubRels: prev.clubRels.map((cr) => {
            if (cr.year_booking_status_id === 6) {
              return {
                ...cr,
                year_booking_round_dates: cr.year_booking_round_dates?.map((rd) => {
                  if (rd.id === rounds?.find((r) => r.id === activeDateIds?.[0])?.id) {
                    return {
                      ...rd,
                      is_fixed: 1,
                      teams: teamSubmitRes.data?.data?.teams,
                      round_options: teamSubmitRes.data?.data?.roundOptions,
                    };
                  }
                  return { ...rd };
                }),
              };
            }
            return cr;
          }),
        };
      });
    } catch (e) {
      console.log("e :", e);
      alert("팀 등록 실패");
      window.location.reload();
    } finally {
      setIsSubmitLoading(false);
    }

    return;
  };
  if (!forcedRerenderFlag) {
    return <></>;
  }
  return (
    <>
      {isSubmitLoading && <SreenCoverLoading />}
      <Container className="page-pd flex-1 d-flex fd-c gap-1">
        <div className="content flex-1 d-flex fd-c gap-2">
          <p>연간 부킹 팀원 등록은 3일전까지 등록해주시기 바랍니다.</p>
          <DatePopupSelect />
          {activeDateIds?.length ? (
            <>
              <div className={`d-flex fd-c gap-3  ${currentRound?.is_fixed ? "no-click" : ""}`}>
                <div>
                  {currentRound?.is_fixed ? (
                    <p className="complete">멤버 및 옵션 제출 완료</p>
                  ) : (
                    <></>
                  )}
                </div>
                {teams?.map((t, idx) => {
                  const memberCount =
                    t.year_booking_round_date_team_rel_booking_group_member?.length;
                  return (
                    <div key={`${t.team_number}-${idx}`} className={`d-flex fd-c jc-sb`}>
                      <div>{t.team_number} 팀</div>
                      <div className="d-flex gap-2">
                        <div className="members-container flex-1">
                          {t.year_booking_round_date_team_rel_booking_group_member?.map((m) => {
                            const name = group.members.find(
                              (_m) => _m.id === m.booking_group_member_id
                            )?.name;
                            return (
                              <div
                                className="box"
                                // key={`${m.id}-${m.name}`}
                                key={`${m.booking_group_member_id}`}
                                onClick={() => {
                                  if (
                                    window.confirm(
                                      `${t.team_number}팀에서 ${name}님을 제외 시키시겠습니까?`
                                    )
                                  ) {
                                    setTeams((teams) => {
                                      if (t.id && m.id) {
                                        if (!t.deleteMembers) {
                                          t.deleteMembers = [];
                                        }
                                        t.deleteMembers.push({
                                          team_id: t.id,
                                          team_member_id: m.id,
                                        });
                                      }
                                      t.year_booking_round_date_team_rel_booking_group_member =
                                        t.year_booking_round_date_team_rel_booking_group_member?.filter(
                                          (_m) => _m.id !== m.id
                                        );
                                      return [...(teams ?? [])];
                                    });
                                  }
                                }}
                              >
                                {name}
                              </div>
                            );
                          })}
                          {(() => {
                            return new Array(4 - (memberCount ?? 0)).fill("-").map((x, idx) => {
                              return (
                                <div className="box" key={`${idx}-emptybox-${t.team_number}`}>
                                  {x}
                                </div>
                              );
                            });
                          })()}
                        </div>
                        <Button
                          $colorTheme="lightGray"
                          $round
                          onClick={() => {
                            if (
                              (t.year_booking_round_date_team_rel_booking_group_member?.length ??
                                0) >= 4
                            ) {
                              alert(
                                "멤버가 가득찼습니다. 멤버를 해제하시려면 멤버이름을 터치하세요."
                              );
                              return;
                            }
                            setCurrentSelectedTeamNumber(t.team_number);
                            openMemberSearchPopup();
                          }}
                        >
                          등록
                        </Button>
                      </div>
                    </div>
                  );
                })}
              </div>
              <Button
                className={`mt-2 ${currentRound?.is_fixed ? "no-click" : ""}`}
                $round
                $colorTheme="green"
                onClick={() => {
                  setTeams((teams) => {
                    return [
                      ...(teams ?? []),
                      {
                        team_number: Math.max(...(teams?.map((t) => t.team_number) ?? [0])) + 1,
                        year_booking_round_date_team_rel_booking_group_member: [],
                      },
                    ];
                  });
                }}
              >
                팀 추가
              </Button>
              <div>
                {isOptionLoading ? (
                  <>loading..</>
                ) : (
                  <div className={`mt-2 ${currentRound?.is_fixed ? "no-click" : ""}`}>
                    <OptionBoxes
                      initialOptionIds={initialOptions}
                      allOptions={allOptions}
                      setSelectedOptionIds={setSelectedOptionIds}
                    />
                  </div>
                )}
              </div>
              <div>
                {currentRound?.is_fixed ? (
                  <p className="mt-2 complete">멤버 및 옵션 제출 완료</p>
                ) : (
                  <></>
                )}
              </div>
            </>
          ) : (
            <>
              <div>라운드 일자를 선택하세요.</div>
            </>
          )}
        </div>
        <DoubleButton
          nagativeMargin
          left={{ name: "취소", size: "md", themeColor: "lightGray", onClick: () => navigate(-1) }}
          right={{
            name: "저장",
            size: "md",
            themeColor: "green",
            buttonCompProps: {
              disabled: !Boolean(activeDateIds?.length) || Boolean(currentRound?.is_fixed),
            },
            onClick: () => {
              handleSubmit();
            },
          }}
        />
      </Container>
      <MemeberSearchPopup
        headerMsg="팀원 선택"
        content={
          <>
            <MemberSearchPopupContents
              teams={teams}
              closeMe={() => {
                closeMemberSearchPopup();
              }}
              currentTeamNumber={currentSelectedTeamNumber}
              setTeams={setTeams}
            />
          </>
        }
      />
    </>
  );
}

const Container = styled.div`
  height: calc(100dvh - 55px);
  .members-container {
    display: flex;
    gap: 10px;
    > .box {
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px solid black;
      width: 50px;
    }
  }
  p.complete {
    display: flex;
    justify-content: center;
    font-weight: bold;
    font-size: 18px;
  }
`;

const OptionBoxes = (props: {
  setSelectedOptionIds: any;
  allOptions?: { id: number; name: string }[];
  initialOptionIds?: number[];
}) => {
  const { activeIds, RadioBoxGroup } = useRadioBoxGroup({
    items: props.allOptions ?? [],
    multipleValue: true,
    multiLineColumnCount: 3,
    boxType: "round",
    defaultSelectedIds: props.initialOptionIds?.length ? props.initialOptionIds : [],
  });

  useEffect(() => {
    props.setSelectedOptionIds(activeIds);
  }, [activeIds]);

  return (
    <>
      <br />
      <RadioBoxGroup />
    </>
  );
};

const TeamSelectorContainer = styled.div``;
