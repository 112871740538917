import { useState } from "react";
import { FaCheck } from "react-icons/fa";
import { VscTriangleDown } from "react-icons/vsc";
import { colors } from "src/styles/theme";
import styled, { css } from "styled-components";

// export default function RadioBoxGroup(props: { items: { id: number; name: string }[] }) {
//   return (
//     <Container>
//       {props.items.map((item) => {
//         return (
//           <div className="box" key={item.id}>
//             {item.name}
//           </div>
//         );
//       })}
//     </Container>
//   );
// }
type BoxType = "box" | "round";
export default function useRadioBoxGroup(args: {
  multipleValue?: boolean;
  items: { id: number; name: string; fontSize?: string }[];
  defaultSelectedIds?: number[];
  rowCount?: number;
  multiLineColumnCount?: number;
  subSelectIds?: number[];
  boxType?: BoxType;
  checkBoxType?: boolean;
  readOnly?: boolean;
  fontSize?: string;
}) {
  const [activeIds, setActiveIds] = useState(() => {
    if (!args.defaultSelectedIds) {
      return;
    }
    if (((args.defaultSelectedIds && args.defaultSelectedIds?.length) ?? -1) > 0) {
      if (args.multipleValue) {
        return [...args.defaultSelectedIds];
      }
      return [args.defaultSelectedIds?.[0]];
      // return [...args.defaultSelectedIds]
    }
    return;
  });

  function RadioBoxGroup() {
    const boxType = args?.boxType ?? "box";
    return (
      <Container
        $checkBoxType={args.checkBoxType}
        $boxType={boxType}
        $multiLineColunCount={args.multiLineColumnCount}
        $readOnly={args.readOnly}
        $fontSize={args.fontSize}
      >
        {args.items.map((item) => {
          return (
            <div
              style={{ fontSize: item.fontSize ?? "" }}
              className={
                (() => {
                  const classListArr = ["box"];
                  if (activeIds?.includes(item.id)) {
                    classListArr.push("active");
                  }
                  if (args.subSelectIds?.includes(item.id)) {
                    classListArr.push("sub-active");
                  }
                  return classListArr.join(" ");
                })()
                // `box ${activeIds?.includes(item.id) ? "active" : ""}`
              }
              key={item.id}
              onClick={() => {
                if (activeIds?.includes(item.id)) {
                  setActiveIds((ids) => {
                    console.log("current :", ids);
                    const new1 = ids?.filter((id) => id !== item.id);
                    console.log("new1 :", new1);
                    return new1;
                  });
                } else {
                  if (!args.multipleValue) {
                    setActiveIds([item.id]);
                  } else {
                    setActiveIds((ids) => [...(ids ?? []), item.id]);
                  }
                }
              }}
            >
              {args.checkBoxType ? (
                <>
                  {" "}
                  <FaCheck className={activeIds?.includes(item.id) ? "active" : ""} />{" "}
                </>
              ) : (
                <>{item.name}</>
              )}
              {}
            </div>
          );
        })}
      </Container>
    );
  }

  return { RadioBoxGroup, activeIds };
}

const themeObj: {
  borderRadius: { [key in BoxType]: string };
  gap: { [key in BoxType]: string };
} = {
  gap: {
    box: "2px",
    round: "10px",
  },
  borderRadius: {
    box: "0px",
    round: "999px",
  },
};

const Container = styled.div<{
  $boxType: BoxType;
  $rowCount?: number;
  $multiLineColunCount?: number;
  $checkBoxType?: boolean;
  $readOnly?: boolean;
  $fontSize?: string;
}>`
  ${(p) => {
    if (p.$multiLineColunCount) {
      return css`
        display: grid;
        grid-template-columns: repeat(${p.$multiLineColunCount}, 1fr);
      `;
    }
    return css`
      display: flex;
    `;
  }}

  ${(p) => {
    if (p.$readOnly) {
      return css`
        pointer-events: none;
      `;
    }
    return "";
  }}
  gap: ${(p) => themeObj.gap[p.$boxType]};

  .box {
    overflow-x: hidden;
    word-break: keep-all;
    font-size: ${(p) => p.$fontSize || "14px"};
    flex: 1;
    min-height: 40px;
    border-radius: ${(p) => themeObj.borderRadius[p.$boxType]};
    &.sub-active {
      background-color: ${colors.gray.first};
    }
    &.active {
      background-color: ${colors.green.second};
      color: white;
    }
    background-color: ${colors.gray.second};
    color: black;
    padding: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  ${(p) => {
    if (p.$checkBoxType) {
      return css`
        .box {
          background-color: #fff;
          svg {
            color: ${colors.gray.third};
          }
          &.active {
            background-color: #fff;
            svg {
              color: ${colors.green.first};
            }
          }
        }
      `;
    }
    return css``;
  }}
`;
