import { Outlet, useLocation, useNavigate, useRoutes } from "react-router-dom";
import { ReactNode, useEffect } from "react";
import styled from "styled-components";
import { colors } from "src/styles/theme";
import { IoArrowBack } from "react-icons/io5";
import Button from "../Button";
// import { FaArrowLeft } from "react-icons/fa";
import { FaArrowLeft } from "react-icons/fa6";

// import Button from "../Button";
// import { IoArrowBack } from "react-icons/io5";

const layoutObj: {
  [key in string]: {
    backBtn?: boolean;
    title?: ReactNode;
  };
} = {
  "/year-booking/group-register": {
    backBtn: true,
    title: <div className="d-flex jc-c bold">단체 정보 등록</div>,
  },
  "/year-booking/booking-register": {
    backBtn: true,
    title: (
      <div className="d-flex jc-c bold">연간 부킹 신청</div>
      // <div className="group-regi-header-content">
      //   <p className="left">1. 단체 정보 등록</p>
      //   <p className="right active">{" > "}2.연간 부킹 신청</p>
      // </div>
    ),
  },
  "/year-booking/group-manage": {
    backBtn: true,
    title: <div className="d-flex jc-c bold">단체정보 관리</div>,
  },
  "/year-booking/group-members": {
    backBtn: true,
    title: <div className="d-flex jc-c bold">회원 관리</div>,
  },
  "/year-booking/group-members/register-all": {
    backBtn: true,
    title: <div className="d-flex jc-c bold">회원 일괄 등록</div>,
  },
  "/year-booking/group-members/list": {
    backBtn: true,
    title: <div className="d-flex jc-c bold">회원 리스트</div>,
  },
  "/year-booking/team-register": {
    backBtn: true,
    title: <div className="d-flex jc-c bold">팀 배정</div>,
  },
};

export default function SimpleLayout() {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <>
      <Header />
      <OutletCover>
        <Outlet />
      </OutletCover>
    </>
  );
}

const Header = (props?: { backBtn?: boolean; title?: string }) => {
  const navigete = useNavigate();
  const lc = useLocation();

  const compData = layoutObj[lc.pathname as keyof typeof layoutObj];
  const Comp2: any = () => {
    return compData.title;
  };

  return (
    <StyledHeader className="pd-2">
      {compData?.backBtn && (
        <Button onClick={() => navigete(-1)}>
          <FaArrowLeft size={"20px"} fontWeight={"normal"} />
        </Button>
      )}
      <h2>
        <Comp2 />
      </h2>
      <span></span>
    </StyledHeader>
  );
};

const OutletCover = styled.div`
  height: calc(100dvh - 55px);
  overflow: auto;
`;
const StyledHeader = styled.header`
  background-color: #fff;
  border-bottom: 1px solid ${colors.gray.second};
  width: 100%;
  height: 55px;
  min-height: 55px;
  max-height: 55px;
  display: flex;
  align-items: center;
  > :first-child,
  > :last-child {
    min-width: 30px;
  }
  h2 {
    font-size: 18px;

    flex: 1;
  }
  .group-regi-header-content {
    font-size: 16px;
    font-weight: bold;
    display: flex;
    align-items: center;
    gap: 10px;
    margin-left: 5px;
    .left {
    }
    .right {
    }
    :not(.active) {
      color: ${colors.gray.second};
    }
  }
`;
