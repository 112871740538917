import React from "react";

function KakaoLoad() {
  return (
    <div>
      KakaoLoad
      <button
        onClick={() => {

          console.log(' history back123:', );
          window.history.back();
          // window.close();
        }}
      >
        닫기
      </button>
    </div>
  );
}

export default KakaoLoad;
