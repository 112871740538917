import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import { DoubleButton, Input, Label, SreenCoverLoading } from "src/components";
import { usePopupLocation } from "src/components/PopupLocation";
import { usePopupSelectV2 } from "src/components/PopupSelect";
import { useGroupLocation, useGroupTypes } from "src/hooks";
import { createTeeshotAxiosInstance } from "src/http";
import { recoilStates } from "src/recoil/recoilStates";
import styled from "styled-components";

export default function YearGroupManage() {
  const [group, setGroup] = useRecoilState(recoilStates.group);
  const groupTypes = useGroupTypes();
  const groupLocations = useGroupLocation();

  const {
    PopupSelect: GroupTypePopupSelect,
    activeIds: activeGroupTypeIds,
    propsToInject: propsToInjectFirst,
  } = usePopupSelectV2({
    defaultSelectIds: group?.booking_group_character_id ? [group?.booking_group_character_id] : [],
  });

  const { PopupLocation, propsToInject, activeChildId, activeParentId } = usePopupLocation({
    defaultSelectIds: group.district_id ? [group.district_id] : [],
    items: groupLocations,
  });
  const { value: nameValue, onChange: handleChangeName } = Input.hooks.useInput(group.name);
  const { value: urlValue, onChange: handleChangeUrl } = Input.hooks.useInput(group.group_sns_url);
  const { value: descValue, onChange: handleChangeDesc } = Input.hooks.useInput(group.group_desc);
  const { base64, fileName, handleFileInput, incodeLoading, previewTempUrl } =
    Input.hooks.useFileInput();
  const [isNewImg, setIsNewImg] = useState(false);
  const navigate = useNavigate();
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  return (
    <>
      {isSubmitLoading && <SreenCoverLoading />}
      <Container className="page-pd d-flex fd-c jc-sb gap-2 flex-1">
        <div className="d-flex fd-c gap-2">
          <div>
            <Input
              isEssential={true}
              label="단체명"
              attr={{ value: nameValue, onChange: handleChangeName }}
            />
          </div>
          <div>
            <GroupTypePopupSelect
              {...propsToInjectFirst}
              items={groupTypes ?? []}
              placeholder={"선택해주세요"}
              label={"단체성격"}
              isEssential={true}
            />
          </div>
          <div>
            <PopupLocation
              items={groupLocations}
              isEssential={true}
              label={"활동지역"}
              placeholder={"선택해주세요"}
              {...propsToInject}
            />
          </div>
          <div>
            <Input
              label="단체 카페/밴드 주소"
              attr={{ value: urlValue, onChange: handleChangeUrl }}
            />
          </div>
          <div>
            <Input label="단체 소개" attr={{ value: descValue, onChange: handleChangeDesc }} />
          </div>

          <div>
            <Input
              label="단체 사진(로고)"
              fileName={fileName}
              imgUrl={!isNewImg ? group.group_logo_url : previewTempUrl ?? ""}
              attr={{
                type: "file",
                onChange: (e) => {
                  console.log(" imageChagned!:");
                  setIsNewImg(true);
                  handleFileInput(e);
                },
              }}
            />
          </div>
        </div>
        <DoubleButton
          nagativeMargin
          left={{
            name: "취소",
            themeColor: "lightGray",
            onClick: () => {
              navigate(-1);
            },
          }}
          right={{
            name: "저장",
            themeColor: "green",
            onClick: () => {
              (async () => {
                const submitData: any = {
                  nameValue: nameValue.trim(),
                  urlValue: urlValue.trim(),
                  descValue: descValue.trim(),
                  groupCharacterId: activeGroupTypeIds?.[0],
                  groupDistrictId: (() => {
                    if (!activeParentId) {
                      return null;
                    }
                    return `${activeParentId}${activeChildId || "000"}`;
                  })(),
                };
                if (isNewImg) {
                  submitData.groupImgBase64 = base64;
                }
                if (
                  !submitData?.nameValue ||
                  !submitData?.groupCharacterId ||
                  !submitData?.groupDistrictId
                ) {
                  return alert("필수 정보 미입력");
                }

                try {
                  setIsSubmitLoading(true);
                  const res = await createTeeshotAxiosInstance().put("/yearBooking/group", {
                    groupId: group.id,
                    submitData,
                  });
                  if (!res.data.success) {
                    throw new Error();
                  }

                  const newGroupData = res.data?.data?.group;
                  setGroup((prev) => {
                    return { ...prev, ...newGroupData };
                  });
                } catch (e) {
                  alert("그룹정보 변경 실패");
                  console.log("e :", e);
                  window.location.reload();
                } finally {
                  setIsSubmitLoading(false);
                  navigate(-1);
                }
              })();
            },
          }}
        />
      </Container>
    </>
  );
}

const Container = styled.div``;
