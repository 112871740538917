import { useRecoilState } from "recoil";
import ContentBox from "../ContentBox";
import { recoilStates } from "src/recoil/recoilStates";
import Button from "../Button";
import styled from "styled-components";
import { setTime23_59_59 } from "src/utils";
import { BookingConditionContents } from "../PopupPageContents";
import usePopupPage from "../PopupPage";
import Stepper from "../Stepper";

export default function HasYearBookingContent() {
  const [group] = useRecoilState(recoilStates.group);
  const [user] = useRecoilState(recoilStates.user);
  const [bookingApplication] = useRecoilState(recoilStates.bookingApplication);
  const clubRelData = bookingApplication?.clubRels?.find((cr) => cr.year_booking_status_id === 6);
  const roundAsc = [...(clubRelData?.year_booking_round_dates ?? [])].sort((a, b) => {
    return new Date(a.round_date).getTime() - new Date(b.round_date).getTime();
  });
  const nextRound = roundAsc.find((round) => {
    return new Date(Date.now()) < setTime23_59_59(new Date(round.round_date));
  });

  const myMemberData = group.members.find((m) => {
    return m.teeshot_user_id && m.teeshot_user_id === user.id;
  });
  const myTeam = nextRound?.teams?.find((team) => {
    return team.year_booking_round_date_team_rel_booking_group_member?.some((mr) => {
      return mr.booking_group_member_id === myMemberData?.id;
    });
  });
  const myTeamMemberData = myTeam?.year_booking_round_date_team_rel_booking_group_member?.find(
    (mr) => {
      return mr.booking_group_member_id === myMemberData?.id;
    }
  );

  return (
    <Container className="d-flex fd-c gap-2">
      <Stepper
        className="page-pd-negative-side page-pd-negative-top"
        currentIdx={4}
        title="멤버 등록 후 팀을 배정하세요~"
        steps={[
          { label: <>단체등록</> },
          { label: "부킹 신청" },
          { label: "조건 협의" },
          { label: "부킹 확정" },
          {
            label: (
              <div className="d-flex fd-c ai-c">
                <div>보증금</div>
                <div>확인</div>
              </div>
            ),
          },
          { label: "멤버 등록" },
        ]}
      />
      <h4 className="bold">다음 라운드 정보</h4>
      <ContentBox
        header={
          <></>
          // <div className="d-flex ai-c jc-c">
          //   <img className="group-img" src={group.group_logo_url} alt="골프클럽로고" />
          // </div>
        }
        content={
          <div className="card d-flex flex-1 fd-c gap-2 jc-sa">
            <div className="row">
              <div className="col head">클럽명</div>
              <div className="col content">{clubRelData?.name}</div>
            </div>
            <div className="row">
              <div className="col head">단체명</div>
              <div className="col content">{group?.name}</div>
            </div>
            {(() => {
              const data = [];
              if (user.realname) {
                data.push(user.realname);
              }
              if (user.gender) {
                data.push({ male: "(남)", female: "(여)" }[user.gender]);
              }
              if (user.age) {
                data.push(`${user.age}세`);
              }
              if (data.length) {
                return <p className="as-c">{data.join(", ")}</p>;
              }
              return null;
            })()}
            <h4 className="as-c bold">다음 라운드 예약내역</h4>
            <div className="row">
              <div className="col head">일시</div>
              <div className="col content">
                {new Date(nextRound?.round_date ?? 0).toLocaleDateString("kr-KR", {
                  dateStyle: "full",
                })}
              </div>
            </div>
            <div className="row">
              <div className="col head">락커</div>
              <div className="col content">
                {myTeamMemberData?.locker_number ? myTeamMemberData?.locker_number : "미정"}
              </div>
            </div>
            <div className="row">
              <div className="col head">팀원</div>
              <div className="col content">
                {myTeam?.year_booking_round_date_team_rel_booking_group_member?.length ? (
                  <>
                    {myTeam?.year_booking_round_date_team_rel_booking_group_member
                      ?.map(
                        (mr) =>
                          group.members.find((gm) => gm.id === mr.booking_group_member_id)?.name
                      )
                      ?.join(", ")}
                  </>
                ) : (
                  "미정"
                )}
              </div>
            </div>
          </div>
        }
      />
      <div className="d-flex fd-c ai-c">
        <p>연간 부킹관련 문의</p>
        <p>티샷: 02-2277-3489 </p>
      </div>
    </Container>
  );
}

const Container = styled.div`
  > * {
    font-size: 18px;
  }

  .card {
    .row {
      display: flex;
      gap: 10px;
      .col {
        &.head {
          flex-basis: 55px;
          font-weight: bold;
          display: flex;
          justify-content: flex-end;
        }
        &.content {
          flex: 1;
        }
      }
    }

    .group-img {
      /* width: 50px;
      height: 50px; */
    }
    .round-content {
      > div {
        display: flex;
        > div:nth-child(2) {
          flex: 1;
          display: flex;
          justify-content: center;
        }
      }
    }

    .head-col {
      width: 60px;
    }
  }
`;
