import { useEffect, useMemo, useState } from "react";
import ProcessHeader from "../ProcessHeader";
import { sleep } from "src/utils";
import ContentBox from "../ContentBox";
import { useRecoilState } from "recoil";
import { recoilStates } from "src/recoil/recoilStates";
import styled from "styled-components";
import { bookingDummys } from "src/services/dummys";
import Button from "../Button";
import usePopupPage from "../PopupPage";
import { BookingConditionContents } from "../PopupPageContents";
import Stepper from "../Stepper";

export default function PaymentWating() {
  useEffect(() => {
    (async () => {
      await sleep();
    })();
    return () => {};
  }, []);

  const [yearBooking, setYearBooking] = useRecoilState(recoilStates.yearBooking);
  const [bookingApplication, setBookingApplication] = useRecoilState(
    recoilStates.bookingApplication
  );

  const clubRelData = useMemo(() => {
    return bookingApplication?.clubRels.find((cr) => cr.year_booking_status_id === 4);
  }, [bookingApplication]);
  return (
    <Container className="d-flex fd-c gap-2">
      {/* <ProcessHeader
        left={{ active: false, msg: "골프장 확정 완료" }}
        right={{ active: true, msg: "보증금을 결제해 주세요." }}
      /> */}
      <Stepper
        className="page-pd-negative-side page-pd-negative-top"
        currentIdx={3}
        title="보증금 결제하세요~"
        steps={[
          { label: <>단체등록</> },
          { label: "부킹 신청" },
          { label: "조건 협의" },
          { label: "부킹 확정" },
          {
            label: (
              <div className="d-flex fd-c ai-c">
                <div>보증금</div>
                <div>확인</div>
              </div>
            ),
          },
          { label: "멤버 등록" },
        ]}
      />
      <p className="pay-comment">
        2024년 1월 31일 까지 아래와 같이 보증금을 결제해 주시기 바랍니다.
      </p>
      <ContentBox
        colorTheme="gray"
        content={
          <div className="flex-1 d-flex jc-sa fd-c">
            <div>
              보증금 합계:{" "}
              {(
                (clubRelData?.meal_deposit ?? 0) + (clubRelData?.round_deposit ?? 0)
              ).toLocaleString()}{" "}
              원
            </div>
            <div>
              <div>예금주: (주)에이치비엠피</div>
              <div>은행명: 우리은행</div>
              <div>계좌번호: 1005-302-929567</div>
            </div>
          </div>
        }
      />
      <p className="pay-comment">입금확인: 티샷 02-2277-3489</p>
    </Container>
  );
}

const Container = styled.div`
  .pay-comment {
    width: 250px;
    align-self: center;
    padding: 15px 0px;
  }
`;
