import { useEffect, useState } from "react";
import Button from "../Button";
// import { usePagePopup } from "../PopupPage";
import styled from "styled-components";
import ContentBox from "../ContentBox";
import { useNavigate } from "react-router-dom";
import { ProposalPopupPageContents } from "../PopupPageContents";
import usePopupPage from "../PopupPage";
import Stepper from "../Stepper";

export default function HasProposal() {
  const { openPopup: openAnnoounce, PopupComponent: AnnounceReadPopup } = usePopupPage("add");

  const { openPopup: openBookingProposalPagePopup, PopupComponent: ProposalPopup } =
    usePopupPage("bookingProposal");
  const [selectedClubInfo, setSelectedClubInfo] = useState<any>(null);
  const clubData = selectedClubInfo?.club?.[0];
  const navigate = useNavigate();
  return (
    <>
      <Container className="d-flex fd-c gap-2">
        <Stepper
          className="page-pd-negative-side page-pd-negative-top"
          currentIdx={2}
          title="조건 검토 후 확정하세요~"
          steps={[
            { label: <>단체등록</> },
            { label: "부킹 신청" },
            { label: "조건 협의" },
            { label: "부킹 확정" },
            {
              label: (
                <div className="d-flex fd-c ai-c">
                  <div>보증금</div>
                  <div>확인</div>
                </div>
              ),
            },
            { label: "멤버 등록" },
          ]}
        />
        <ContentBox
          content={
            <div className="pd-4 content-box-msg-content flex-1 d-flex fd-c gap-2 bold jc-c ">
              <p>부킹 조건을 제시한 골프장이있어요!</p>
              <p>부킹 신청 현황 확인 버튼을 눌러 조건을 확인해보세요.</p>
            </div>
          }
        />
        <Button
          $buttonSize="md"
          $colorTheme="lightGreen"
          $round
          onClick={openBookingProposalPagePopup}
        >
          부킹 신청 현황확인
        </Button>
        <Button
          $colorTheme="black"
          onClick={() => navigate("/year-booking/group-register/result")}
          $round={true}
          $buttonSize="md"
        >
          연간 부킹 신청 내역 확인
        </Button>

        <ProposalPopup
          headerMsg={"골프장 확정하기"}
          type={"back"}
          content={
            <>
              <ProposalPopupPageContents
                setSelectedClubInfo={setSelectedClubInfo}
                openAnnoounce={openAnnoounce}
                closeFn={() => {}}
              />
            </>
          }
        />
        <AnnounceReadPopup
          headerMsg={"모집공고"}
          type={"x"}
          content={
            <ReqContent>
              <div className="img-box">
                <img src={clubData?.recruitment_notice_img_url} alt="" />
              </div>
            </ReqContent>
          }
        />
      </Container>
    </>
  );
}

const Container = styled.div``;

const ReqContent = styled.div`
  overflow: auto;
  .img-box {
    padding: 5px;
    img {
      width: 100%;
    }
  }
`;
