import React, { useState } from "react";
import { styled } from "styled-components";
import { useRecoilState } from "recoil";
import { recoilStates } from "src/recoil/recoilStates";
import ContentBox from "../ContentBox";
import DoubleButton from "../DoubleButton";
import { colors } from "src/styles/theme";
import usePopupPage from "../PopupPage";
import Button from "../Button";
import { sleep } from "src/utils";
import SreenCoverLoading from "../SreenCoverLoading";
import { bookingDummys } from "src/services/dummys";
import { createTeeshotAxiosInstance } from "src/http";
import { useNavigate } from "react-router-dom";
import Divider from "../Divider";

export default function ProposalPopupPageContents(props: {
  openAnnoounce: () => void;
  closeFn: () => void;
  setSelectedClubInfo: any;
}) {
  const [yearBooking, setYearBooking] = useRecoilState(recoilStates.yearBooking);
  const [bookingApplication, setBookingApplication] = useRecoilState(
    recoilStates.bookingApplication
  );
  const navigate = useNavigate();
  const [selectedClubRelId, setSelectedClubRelId] = useState<number>();
  const [agreeChecked, setAgreeChecked] = useState(false);
  const { closePopup } = usePopupPage("bookingProposal"); // or navigate(-1)

  const selectedBooking = yearBooking.bookings.filter((b) => b.id === selectedClubRelId)?.[0];
  const [coverLoadingState, setCoverLoadingState] = useState(false);
  return (
    <>
      <PopupContent className="flex-1 page-pd d-flex fd-c gap-2">
        <div className="bold d-flex gap-1 head-box">
          <div className="left">골프장 조건 등록 {">"}</div>
          <div className="right">골프장을 확정해주세요</div>
        </div>
        <ul className="proposals flex-1 d-flex fd-c gap-2">
          {bookingApplication?.clubRels
            ?.filter((c) => [1, 3, 7].includes(c?.year_booking_status_id)) // 신청 직후 1, 조건제시 3, 클럽거절7
            ?.map((cr) => {
              return (
                <ContentBox
                  key={cr.club_id}
                  asBtn={true}
                  header={
                    <div className="bold d-flex gap-1 ai-c">
                      {cr.year_booking_status_id === 3 && (
                        <input
                          type="checkbox"
                          checked={cr.id === selectedClubRelId}
                          name=""
                          id=""
                        />
                      )}
                      {cr.name}
                    </div>
                  }
                  content={
                    <div className="d-flex fd-c content-box-content">
                      {(() => {
                        switch (cr.year_booking_status_id) {
                          case 1: {
                            return (
                              <>
                                <div>골프장 조건 제안 전</div>
                              </>
                            );
                          }
                          case 7: {
                            return <div className="rejection">골프장 수락 거절</div>;
                          }
                          case 3: {
                            return (
                              <>
                                <div className="d-flex">
                                  <div className="bold row-h">라운드 일시</div>
                                  <div>
                                    <div>
                                      {JSON.parse(cr?.month_arr_json_str || "[]")?.join("월, ")}월{" "}
                                    </div>
                                    <div className="d-flex gap-1">
                                      <div>
                                        {JSON.parse(cr?.week_arr_json_str || "[]")?.join("주차, ")}
                                        주
                                      </div>
                                      <div>
                                        {JSON.parse(cr?.day_arr_json_str || "[]")?.join("요일 ")}
                                        요일
                                      </div>
                                      <div>
                                        {cr?.start_time?.slice(0, 2)}:{cr?.start_time?.slice(2, 4)}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <Divider />
                                <div className="d-flex">
                                  <div className="bold row-h">
                                    <div>일별 비용</div>
                                  </div>
                                  <ul className="d-flex fd-c flex-1">
                                    {cr.year_booking_round_dates?.map((rd, idx) => {
                                      return (
                                        <React.Fragment key={rd.id}>
                                          <li className="d-flex gap-1 jc-sb flex-1">
                                            <div className="flex-3">{rd.round_date}</div>
                                            <div className="flex-4 d-flex fd-c">
                                              <div className="d-flex jc-sb ai-c">
                                                <div className="fs-2">(그린피)</div>{" "}
                                                <div>{rd.green_fee?.toLocaleString()} 원</div>
                                              </div>
                                              <div className="d-flex jc-sb ai-c">
                                                <div className="fs-2">(캐디피)</div>{" "}
                                                <div>{rd.caddy_fee?.toLocaleString()} 원</div>
                                              </div>
                                              <div className="d-flex jc-sb ai-c">
                                                <div className="fs-2">(카트피)</div>{" "}
                                                <div>{rd.cart_fee?.toLocaleString()} 원</div>
                                              </div>
                                            </div>
                                          </li>
                                          {(cr.year_booking_round_dates?.length || 0) - 1 !==
                                            idx && <Divider />}
                                        </React.Fragment>
                                      );
                                    })}
                                  </ul>
                                </div>
                                <Divider />
                                <div className="d-flex">
                                  <div className="bold row-h">보증금</div>
                                  <div>
                                    <div className="d-flex gap-1">
                                      <div>라운드 보증금</div>
                                      <div>{cr?.round_deposit?.toLocaleString()} 원</div>
                                    </div>
                                    <div className="d-flex gap-1">
                                      <div>식사 보증금</div>
                                      <div>{cr?.meal_deposit?.toLocaleString()} 원</div>
                                    </div>
                                  </div>
                                </div>
                                <Divider />
                                <div className="d-flex">
                                  <div className="bold row-h">기타</div>
                                  <div>
                                    <div className="d-flex gap-1">
                                      <div>식음료 객단가</div>
                                      <div>{cr?.meal_price_per_person?.toLocaleString()} 원</div>
                                    </div>
                                    <div className="d-flex gap-1">
                                      <div>{cr?.etc_notification}</div>
                                    </div>
                                  </div>
                                </div>
                              </>
                            );
                          }
                          default: {
                            return <>nocase</>;
                          }
                        }
                      })()}
                    </div>
                  }
                  onClick={() => {
                    if (!cr.id) return;
                    if (cr.year_booking_status_id !== 3) {
                      alert("조건을 제시한 골프장만 선택가능합니다.");
                      return;
                    }
                    if (selectedClubRelId === cr.id) {
                      setSelectedClubRelId(0);
                      return;
                    }
                    props.setSelectedClubInfo(cr);
                    setSelectedClubRelId(cr.id);
                  }}
                  isSelectedBtn={cr.id === selectedClubRelId}
                />
              );
            })}
        </ul>
        <div className={"bottom-section"}>
          {selectedClubRelId ? (
            <div className="check-line">
              <input
                className="book-agree"
                type="checkbox"
                checked={agreeChecked}
                onChange={(e) => {
                  setAgreeChecked(e.target.checked);
                }}
              />
              <p>
                {bookingApplication?.clubRels?.find((cr) => cr?.id === selectedClubRelId)?.name}의
                예약조건을 확인하였고 이에 동의합니다.
              </p>
              <Button
                $colorTheme="black"
                onClick={() => {
                  props.openAnnoounce();
                }}
              >
                모집공고
              </Button>
            </div>
          ) : (
            <></>
          )}
        </div>
        <DoubleButton
          nagativeMargin
          left={{ name: "취소", themeColor: "black", widthRatio: 1, onClick: closePopup }}
          right={{
            name: "골프장 확정",
            themeColor: "green",
            widthRatio: 2,
            buttonCompProps: { disabled: !agreeChecked },
            onClick: () => {
              (async () => {
                setCoverLoadingState(true);
                try {
                  const res = await createTeeshotAxiosInstance().post(
                    "/yearBooking/booking/confirmClub",
                    {
                      clubRelId: selectedClubRelId,
                    }
                  );
                  if (!res.data?.success || !res?.data?.data?.newClubRels?.length) {
                    throw new Error();
                  }
                  const rels = res?.data?.data?.newClubRels;

                  setBookingApplication((prev) => {
                    if (!prev) {
                      return null;
                    }
                    return { ...prev, clubRels: rels };
                  });
                } catch (e) {
                  alert("확정 요청 실패");
                  window.location.reload();
                } finally {
                  setCoverLoadingState(false);
                  navigate("/");
                  window.location.reload();
                }
              })();
            },
          }}
        />
      </PopupContent>
      {coverLoadingState && <SreenCoverLoading />}
    </>
  );
}

const PopupContent = styled.div`
  overflow-y: auto;

  .head-box {
    .right {
      color: ${colors.green.second};
    }
  }
  .rejection {
    color: red;
    font-weight: bold;
  }
  .bottom-section {
    display: flex;
    /* flex:1; */
    .select-comment {
    }
    .check-line {
      flex: 1;
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 10px;
      font-size: 14px;
      input.book-agree {
        width: 20px;
        transform: scale(1.5);
      }
      > p {
        flex: 1;
      }
      > button {
        flex-basis: 90px;
        align-self: stretch;
      }
    }
  }

  .content-box-content {
    .row-h {
      width: 100px;
    }
  }
`;
