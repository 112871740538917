import React from "react";
import Button from "./Button";
import { useRecoilState } from "recoil";
import { recoilStates } from "src/recoil/recoilStates";
import { useNavigate } from "react-router-dom";

export default function MainPageButtons() {
  const [group] = useRecoilState(recoilStates.group);
  const hasGroup = Boolean(group.id);
  const navigate = useNavigate();

  if (!hasGroup) {
    return <></>;
  }
  return (
    <div className="d-flex fd-c gap-2 mt-2">
      <Button
        $round
        $buttonSize="md"
        $colorTheme="black"
        onClick={() => navigate("/year-booking/group-manage")}
      >
        단체 정보 관리
      </Button>
      <Button
        $round
        $buttonSize="md"
        $colorTheme="black"
        onClick={() => navigate("/year-booking/group-members")}
      >
        회원 관리
      </Button>
    </div>
  );
}
