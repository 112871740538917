import { useNavigate } from "react-router-dom";
import { Button } from "src/components";
import styled from "styled-components";

export default function YearGroupMemebers() {
  const navigate = useNavigate();
  return (
    <Container className="page-pd d-flex fd-c gap-2">
      <Button
        $colorTheme="black"
        $buttonSize="md"
        $round
        onClick={() => {
          navigate("/year-booking/group-members/list");
        }}
      >
        회원 목록
      </Button>
      <Button
        $colorTheme="black"
        $buttonSize="md"
        $round
        onClick={() => {
          alert("준비중");
          // navigate("/year-booking/group-members/register-all");
        }}
      >
        회원 일괄등록
      </Button>
      {/* <Button $colorTheme="black" $buttonSize="md" $round>
        임원 등록
      </Button> */}
      <Button
        $colorTheme="yellow"
        $buttonSize="md"
        $round
        onClick={() => {
          window.open(`${window.location.origin}/kakao-load?groupCode=A123`);
        }}
      >
        카카오톡으로 초대1
      </Button>
      <Button
        $colorTheme="yellow"
        $buttonSize="md"
        $round
        onClick={() => {
          window.open(
            "https://developers.kakao.com/tool/demo/message/kakaolink?default_template=feed",
            "_blank",
            "visible=none"
          );
          // alert("준비중");
        }}
      >
        테스트
      </Button>
      <div></div>
    </Container>
  );
}

const Container = styled.div``;
