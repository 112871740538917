import { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { selector, useRecoilState, useRecoilValue } from "recoil";
import {
  ContentBox,
  FindInputButton,
  Label,
  SreenCoverLoading,
  useRadioBoxGroup,
  Input,
  DoubleButton,
  usePopupPage,
  Modal,
} from "src/components";

import {
  AnnouncementAgreePopupContents,
  ClubFindPopupContents,
} from "src/components/PopupPageContents";
import { usePopupSelectV2 } from "src/components/PopupSelect";
import SignModalContent from "src/components/YearBooking/SignModalContent";

import { useGroupData, useGroupLocation } from "src/hooks";
import { createTeeshotAxiosInstance } from "src/http";
import { recoilStates } from "src/recoil/recoilStates";
import { bookingDummys } from "src/services/dummys";
import { caddyOptionData } from "src/staticData";
import { colors } from "src/styles/theme";
import { ClubType, SelectedClubType } from "src/types";
import { sleep } from "src/utils";
import styled from "styled-components";

const times = [
  { id: 1, name: "06시", str: "0600" },
  { id: 2, name: "07시", str: "0700" },
  { id: 3, name: "08시", str: "0800" },
  { id: 4, name: "09시", str: "0900" },
  { id: 5, name: "10시", str: "1000" },
  { id: 6, name: "11시", str: "1100" },
  { id: 7, name: "12시", str: "1200" },
  { id: 8, name: "13시", str: "1300" },
  { id: 9, name: "14시", str: "1400" },
  { id: 10, name: "15시", str: "1500" },
  { id: 11, name: "16시", str: "1600" },
  { id: 12, name: "17시", str: "1700" },
  { id: 13, name: "18시", str: "1800" },
  { id: 14, name: "19시", str: "1900" },
  { id: 15, name: "20시", str: "2000" },
  { id: 16, name: "21시", str: "2100" },
];

const days = [
  { id: 1, name: "월" },
  { id: 2, name: "화" },
  { id: 3, name: "수" },
  { id: 4, name: "목" },
  { id: 5, name: "금" },
  { id: 6, name: "토" },
  { id: 7, name: "일" },
];

export default function YearBookingRegister() {
  const lc = useLocation();
  const navigate = useNavigate();
  const params = new URLSearchParams(lc.search);
  // const groupData = useGroupData(Number(groupId ?? 0));
  const [isGroupDataLoading, setIsGroupDataLoading] = useState(true);
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const groupLocations = useGroupLocation();
  const [group] = useRecoilState(recoilStates.group);
  const [user] = useRecoilState(recoilStates.user);
  const [yearBooking, setYearBooking] = useRecoilState(recoilStates.yearBooking);

  const hasYearBookingRegister = Boolean(yearBooking.bookings?.length);
  useEffect(() => {
    if (
      // groupData &&

      groupLocations
    ) {
      setIsGroupDataLoading(false);
    }
  }, [
    // groupData,
    groupLocations,
  ]);
  const [selectedClubs, setSelectedClubs] = useState<SelectedClubType[]>();

  const [announcementShowingClub, setAnnouncementShowingClub] = useState<ClubType>();

  const [isSignModalOpened, setIsSignModalOpened] = useState(false);
  const [signBase64, setSignBase64] = useState<string | null>(null);

  const {
    PopupComponent: AnnouncePopupComp,
    openPopup: openAnnouncePopup,
    closePopup: closeAnnounce,
  } = usePopupPage("announcement");

  const {
    PopupComponent: ClubFindPopupComp,
    openPopup: openClubSearchPopup,
    closePopup: closeClubSearchPopup,
  } = usePopupPage("clubSearch");

  const { RadioBoxGroup: TeamRadioBoxGroup, activeIds: selectedTeamCountIds } = useRadioBoxGroup({
    items: [
      { id: 2, name: "2팀" },
      { id: 3, name: "3팀" },
      { id: 4, name: "4팀" },
      { id: 5, name: "5팀" },
      { id: 6, name: "6팀" },
      { id: 7, name: "7팀" },
      { id: 8, name: "8팀" },
      { id: 9, name: "9팀" },
      { id: 10, name: "10팀~" },
      { id: 15, name: "15팀~" },
    ],
    defaultSelectedIds: [1, 2],
    // multipleValue: true,
    multiLineColumnCount: 5,
  });

  const { RadioBoxGroup: MonthRadioBoxGroup, activeIds: selectedMonthIds } = useRadioBoxGroup({
    items: [
      { id: 1, name: "1월" },
      { id: 2, name: "2월" },
      { id: 3, name: "3월" },
      { id: 4, name: "4월" },
      { id: 5, name: "5월" },
      { id: 6, name: "6월" },
      { id: 7, name: "7월" },
      { id: 8, name: "8월" },
      { id: 9, name: "9월" },
      { id: 10, name: "10월" },
      { id: 11, name: "11월" },
      { id: 12, name: "12월" },
    ],
    multipleValue: true,
    multiLineColumnCount: 6,
  });
  const { RadioBoxGroup: WeekRadioGroupFirst, activeIds: selectedWeekIdsFirst } = useRadioBoxGroup({
    items: [
      { id: 1, name: "1주" },
      { id: 2, name: "2주" },
      { id: 3, name: "3주" },
      { id: 4, name: "4주" },
    ],
    // multipleValue: true,
    // multiLineColumnCount: 6,
  });
  const { RadioBoxGroup: WeekRadioGroupSecond, activeIds: selectedWeekIdsSecond } =
    useRadioBoxGroup({
      subSelectIds: selectedWeekIdsFirst,
      items: [
        { id: 1, name: "1주" },
        { id: 2, name: "2주" },
        { id: 3, name: "3주" },
        { id: 4, name: "4주" },
      ],
    });
  const { RadioBoxGroup: DayRadioGroupFirst, activeIds: selectedDayIdsFirst } = useRadioBoxGroup({
    items: days,
  });
  const { RadioBoxGroup: DayRadioGroupSecond, activeIds: selectedDayIdsSecond } = useRadioBoxGroup({
    subSelectIds: selectedDayIdsFirst,
    items: days,
  });

  const {
    PopupSelect: TimePopupSelectFirst,
    activeIds: activeTimeIdsFirst,
    propsToInject: propsToInjectFirst,
  } = usePopupSelectV2({
    defaultSelectIds: [1],
  });
  const {
    PopupSelect: TimePopupSelectSecond,
    activeIds: activeTimeIdsSecond,
    propsToInject: propsToInjectSecond,
  } = usePopupSelectV2({
    defaultSelectIds: [1],
  });

  const { RadioBoxGroup: PlayTypeBoxGroup, activeIds: activePlayTypeIds } = useRadioBoxGroup({
    boxType: "round",
    items: [
      { id: 1, name: "신페리오" },
      { id: 2, name: "스트로크" },
      { id: 3, name: "매치플레이" },
    ],
  });
  const [isMealActive, setIsMealActive] = useState(false);
  const { RadioBoxGroup: PricePerPersonBoxGroup, activeIds: activePricePerPersonId } =
    useRadioBoxGroup({
      boxType: "round",
      items: [
        { id: 1, name: "1만원" },
        { id: 2, name: "2만원" },
        { id: 3, name: "3만원" },
        { id: 4, name: "4만원" },
        { id: 5, name: "5만원+" },
      ],
    });

  const { RadioBoxGroup: TeeTypeBoxGroup, activeIds: activeTeeTypeIds } = useRadioBoxGroup({
    boxType: "round",
    items: [
      { id: 1, name: "블루티" },
      { id: 2, name: "화이트티" },
      { id: 3, name: "레드티" },
      { id: 4, name: "실버티" },
    ],
  });
  const { RadioBoxGroup: EtcRequirementsBoxGroup, activeIds: activeEtcRequirementsIds } =
    useRadioBoxGroup({
      multipleValue: true,
      boxType: "round",
      items: [
        { id: 1, name: "스코어카드" },
        { id: 2, name: "기념촬영" },
        { id: 3, name: "현수막" },
      ],
    });

  const { RadioBoxGroup: AwardsBoxGroup, activeIds: activeAwardsIds } = useRadioBoxGroup({
    multipleValue: true,
    boxType: "round",
    multiLineColumnCount: 4,
    // fontSize: "12px",
    items: [
      { id: 1, name: "우승" },
      { id: 2, name: "준우승" },
      { id: 3, name: "3위" },
      { id: 4, name: "행운상" },
      { id: 5, name: "특별상" },
      { id: 6, name: "메달리스트" },
      { id: 7, name: "롱게스트" },
      { id: 8, name: "니어리스트" },
      { id: 9, name: "매너상" },
      { id: 10, name: "베스트드레서", fontSize: "12px" },
    ],
  });

  const { RadioBoxGroup: MealActiveBoxGroup, activeIds: mealActiveIds } = useRadioBoxGroup({
    boxType: "round",
    items: [
      { id: 1, name: "유" },
      { id: 2, name: "무" },
    ],
  });

  const { RadioBoxGroup: MealTypeBoxGroup, activeIds: mealTypeActiveIds } = useRadioBoxGroup({
    boxType: "round",
    items: [
      { id: 1, name: "조식" },
      { id: 2, name: "중식" },
      { id: 3, name: "석식" },
    ],
  });

  const { RadioBoxGroup: RoomActiveBoxGroup, activeIds: roomActiveIds } = useRadioBoxGroup({
    boxType: "round",
    items: [
      { id: 1, name: "유" },
      { id: 2, name: "무" },
    ],
  });

  const { RadioBoxGroup: RoomTypeBoxGroup, activeIds: roomTypeActiveIds } = useRadioBoxGroup({
    boxType: "round",
    items: [
      { id: 1, name: "라운드 전" },
      { id: 2, name: "라운드 후" },
    ],
  });

  const { RadioBoxGroup: ShopActiveBoxGroup, activeIds: shopActiveIds } = useRadioBoxGroup({
    boxType: "round",
    items: [
      { id: 1, name: "유" },
      { id: 2, name: "무" },
    ],
  });

  const { value: shopItemValue, onChange: handleShopItemValueChange } = Input.hooks.useInput("");

  const { RadioBoxGroup: PayTypeBoxGroup, activeIds: activePayTypeIds } = useRadioBoxGroup({
    boxType: "round",
    // checkBoxType: true,
    items: [
      { id: 1, name: "전체 결제" },
      { id: 2, name: "팀별 결제" },
    ],
  });

  const [_1, setBookingApplication] = useRecoilState(recoilStates.bookingApplication);
  const [_2, setBookingClubRelData] = useRecoilState(recoilStates.bookingClubRelData);

  const { value: additionalCommentValue, onChange: onAdditionalCommentChange } =
    Input.hooks.useInput();
  // const navigate = useNavigate()
  const [currentClubPreferenceNumber, setCurrentClubPreferenceNumber] = useState(0);

  const submitData = {
    // user: user,
    clubs: selectedClubs?.map((c) => {
      return { id: c.id, preference: c.preference };
    }),
    teamCount: selectedTeamCountIds?.[0],
    monthIds: selectedMonthIds,
    dateFirst: {
      weeks: selectedWeekIdsFirst,
      day: selectedDayIdsFirst?.map((dayId) => days.find((d) => d.id === dayId)?.name) ?? [],
      time:
        activeTimeIdsFirst?.map((tid) => times.find((t) => t.id === tid)?.str ?? null)?.[0] ?? null,
    },
    dateSecond: {
      weeks: selectedWeekIdsSecond,
      day: selectedDayIdsSecond?.map((dayId) => days.find((d) => d.id === dayId)?.name) ?? [],
      time:
        activeTimeIdsSecond?.map((tid) => times.find((t) => t.id === tid)?.str ?? null)?.[0] ??
        null,
    },
    pricePerPerson: activePricePerPersonId?.[0], //
    playTypeId: activePlayTypeIds?.[0],
    teeTypeId: activeTeeTypeIds?.[0],
    etcRequirementIds: activeEtcRequirementsIds,
    awardsIds: activeAwardsIds?.length ? activeAwardsIds : null,
    mealId: mealActiveIds?.[0] === 1 ? mealTypeActiveIds?.[0] : null,
    roomId: roomActiveIds?.[0] === 1 ? roomTypeActiveIds?.[0] : null,
    shopItem: shopActiveIds?.[0] === 1 ? shopItemValue : null,
    paymentTypeId: activePayTypeIds?.[0],
    additionalComment: additionalCommentValue,
  };
  const checkSubmitData = () => {
    if (
      !submitData.clubs?.length ||
      !submitData.teamCount ||
      !submitData?.dateFirst?.weeks?.length ||
      !submitData?.dateFirst?.day?.length ||
      !submitData?.dateFirst?.time ||
      !submitData?.dateSecond?.weeks?.length ||
      !submitData?.dateSecond?.day?.length ||
      !submitData?.dateSecond?.time
    ) {
      return false;
    }
    return true;
  };

  const handleSubmit = async () => {
    if (!checkSubmitData()) {
      return alert("필수항목을 입력하세요.");
    }

    setIsSubmitLoading(true);
    try {
      const res = await createTeeshotAxiosInstance().post("/yearBooking/booking", {
        submitData: { ...submitData, customerSignBase64: signBase64 },
      });

      if (res.data.success === false) {
        throw new Error();
      }
      const fetchedData = res.data?.data;
      if (fetchedData) {
        setBookingApplication(fetchedData);
      } else {
        throw new Error("데이터 없음");
      }
    } catch (e) {
      console.log("e :", e);
      alert("전송 실패");
    } finally {
      setIsSubmitLoading(false);
      navigate("/year-booking");
    }
  };

  return (
    <>
      <Container className="page-pd d-flex fd-c gap-3">
        <p>희망하시는 연간 부킹 신청 정보를 입력해 주세요.</p>
        {/* <GroupLocationPopupSelect /> */}
        <div className="d-flex fd-c gap-1">
          <Label className="bold" content="희망 골프장" />
          <FindInputButton
            label="1"
            required={true}
            selectedName={selectedClubs?.filter((c) => c.preference === 1)[0]?.name ?? ""}
            onClick={() => {
              setCurrentClubPreferenceNumber(1);
              openClubSearchPopup();
            }}
          />
          <FindInputButton
            label="2"
            required={false}
            selectedName={selectedClubs?.filter((c) => c.preference === 2)[0]?.name ?? ""}
            onClick={() => {
              if (!(selectedClubs && selectedClubs.some((club) => club.preference === 1))) {
                alert("1지망 골프장을 선택하세요.");
                return;
              }
              setCurrentClubPreferenceNumber(2);
              openClubSearchPopup();
            }}
          />
          <FindInputButton
            label="3"
            required={false}
            selectedName={selectedClubs?.filter((c) => c.preference === 3)[0]?.name ?? ""}
            onClick={() => {
              if (!(selectedClubs && selectedClubs.some((club) => club.preference === 2))) {
                alert("1,2지망 골프장을 선택하세요.");
                return;
              }
              setCurrentClubPreferenceNumber(3);
              openClubSearchPopup();
            }}
          />
        </div>
        <div>
          <Label isEssential={true} content="참여 팀수" className="bold" />
          <TeamRadioBoxGroup />
        </div>
        <div>
          <Label isEssential={true} content="희망 월" className="bold" />
          <MonthRadioBoxGroup />
        </div>
        <div>
          <ContentBox
            content={
              <div className="d-flex fd-c gap-1">
                <Label className="as-s bold" isEssential={true} content="희망 일자(1지망)" />
                <div className="d-flex fd-c gap-s">
                  <WeekRadioGroupFirst />
                  <DayRadioGroupFirst />
                </div>
                <TimePopupSelectFirst
                  {...propsToInjectFirst}
                  items={times}
                  label={"시작시간"}
                  boldLabel={true}
                  isEssential={true}
                />
              </div>
            }
          />
        </div>
        <div>
          <ContentBox
            content={
              <div className="d-flex fd-c gap-1">
                <Label className="as-s bold" isEssential={true} content="희망 일자(2지망)" />
                <div className="d-flex fd-c gap-s">
                  <WeekRadioGroupSecond />
                  <DayRadioGroupSecond />
                </div>
                <TimePopupSelectSecond
                  {...propsToInjectSecond}
                  items={times}
                  label={"시작시간"}
                  boldLabel={true}
                  isEssential={true}
                />
              </div>
            }
          />
        </div>
        <div></div>
        <div>
          <Label content="경기방식" className="bold" />
          <PlayTypeBoxGroup />
        </div>
        <div>
          <Label content="티사용" className="bold" />
          <TeeTypeBoxGroup />
        </div>
        <div>
          <Label content="요청사항" className="bold" />
          <EtcRequirementsBoxGroup />
        </div>
        <div>
          <div className="d-flex ai-c mb-1 gap-1">
            <Label content="1인 객단가" className="bold" />
            <p className="fs-2">(* 연단체 우선순위 배정의 기준)</p>
          </div>
          <PricePerPersonBoxGroup />
        </div>
        <div>
          <Label content="시상요청" className="bold" />
          <AwardsBoxGroup />
        </div>
        <div>
          <Label content="식사" className="bold" />
          <div className="d-flex mb-2">
            <div className="flex-1">
              <MealActiveBoxGroup />
            </div>
            <div className="flex-1" />
          </div>
          {mealActiveIds?.[0] === 1 && <MealTypeBoxGroup />}
        </div>
        <div>
          <Label content="룸이용" className="bold" />
          <div className="d-flex mb-2">
            <div className="flex-1">
              <RoomActiveBoxGroup />
            </div>
            <div className="flex-1" />
          </div>
          {roomActiveIds?.[0] === 1 && <RoomTypeBoxGroup />}
        </div>
        <div>
          <Label content="프로샵 이용" className="bold" />
          <div className="d-flex mb-2">
            <div className="flex-1">
              <ShopActiveBoxGroup />
            </div>
            <div className="flex-1" />
          </div>
          {shopActiveIds?.[0] === 1 && (
            <div className="ml-5">
              <Input
                label={"시상품"}
                attr={{ value: shopItemValue, onChange: handleShopItemValueChange }}
              />
            </div>
          )}
        </div>

        <div>
          <Label content="결제" className="bold" />
          <PayTypeBoxGroup />
        </div>
        <div className="mb-3">
          <Label content="기타사항" className="bold" />
          <Input attr={{ value: additionalCommentValue, onChange: onAdditionalCommentChange }} />
        </div>
        <div className="d-flex jc-c">
          <p>희망하신 골프장에서 확인 후 곧 연락드리겠습니다.</p>
        </div>
        <DoubleButton
          nagativeMargin
          left={{
            name: "취소",
            themeColor: "lightGray",
            onClick: () => {
              navigate("/booking-register");
            },
          }}
          right={(() => {
            if (hasYearBookingRegister) {
              return {
                name: "수정",
                themeColor: "green",
                onClick: () => {
                  handleSubmit();
                },
              };
            }
            return {
              name: "신청",
              themeColor: "green",
              onClick: () => {
                if (!checkSubmitData()) {
                  alert("필수 입력사항을 입력하세요.");
                  return;
                }

                setIsSignModalOpened(true);
              },
            };
          })()}
        />
        {/*TODO 신청확인 얼럿*/}
      </Container>
      {isSignModalOpened && (
        <>
          <Modal
            setModalState={setIsSignModalOpened}
            onConfirm={() => {
              handleSubmit();
            }}
            rejectConfirmCondition={Boolean(!signBase64)}
            rejectConfirmMsg="먼저 서명을 진행하세요."
            content={
              <>
                <SignModalContent setSignBase64={setSignBase64} />
              </>
            }
          />
        </>
      )}
      <ClubFindPopupComp
        type={"back"}
        headerMsg={"검색"}
        content={
          <>
            <ClubFindPopupContents
              setAnnouncementShowingClub={setAnnouncementShowingClub}
              openAnnounceAgree={openAnnouncePopup}
              groupLocations={groupLocations}
            />
          </>
        }
      />
      <AnnouncePopupComp
        headerMsg={"연간부킹 신청안내"}
        type={"back"}
        content={
          <>
            <>
              <AnnouncementAgreePopupContents
                closeMe={closeAnnounce}
                closeParent={closeClubSearchPopup}
                announcementShowingClub={announcementShowingClub}
                preference={currentClubPreferenceNumber}
                setSelectedClubs={setSelectedClubs}
              />
            </>
          </>
        }
      />
      {isSubmitLoading && <SreenCoverLoading />}
      {isGroupDataLoading && <SreenCoverLoading />}
    </>
  );
}

const Container = styled.div`
  .custom-table-radio {
    background-color: black;
    padding: 1px;
    display: flex;
    flex-direction: column;
    gap: 1px;
    .t-head {
      display: flex;
      gap: 1px;

      .head-entry {
        width: 80px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: ${colors.gray.second};
      }
      .head-content {
        background-color: black;
        display: flex;
        flex: 1;
        gap: 2px;
        > * {
          flex: 1;

          background-color: ${colors.gray.second};
          display: flex;
          justify-content: center;
          /* gap: 10px; */
        }
      }
    }
    .row {
      display: flex;
      gap: 1px;
      > div {
        &:nth-of-type(1) {
          width: 80px;
          background-color: white;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 5px;
          font-size: 14px;
        }
        &:nth-of-type(2) {
          flex: 1;
        }
      }
    }
    &.play-req {
    }
  }
`;
