import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import { DoubleButton, Input, Modal, SreenCoverLoading, usePopupSelect } from "src/components";
import { usePopupLocation } from "src/components/PopupLocation";
import { usePopupSelectV2 } from "src/components/PopupSelect";
import { useBackListener, useGroupLocation, useGroupTypes } from "src/hooks";
import { createTeeshotAxiosInstance } from "src/http";
import { recoilStates } from "src/recoil/recoilStates";
import { fileToBase64, sleep } from "src/utils";
import styled from "styled-components";
// import { useInput } from "src/components/Input";

export default function YearGroupRegister() {
  const [user] = useRecoilState(recoilStates.user);
  const { value: groupNameValue, onChange: onChangeGroupName } = Input.hooks.useInput("");
  const { value: proposerNameValue, onChange: onChangeProposerName } = Input.hooks.useInput(
    user.realname || user.username
  );
  const { value: contactNumberValue, onChange: onChangeContactNumberValue } = Input.hooks.useInput(
    user.phone,
    new RegExp("^[0-9]*$")
  );
  const { value: groupSnsUrlValue, onChange: onChangeGroupSnsUrlValue } = Input.hooks.useInput("");
  // const { value: groupSnsUrlValue, onChange: onChangeGroupSnsUrlValue } = Input.hooks.("");
  const { base64, fileName, handleFileInput, incodeLoading, previewTempUrl } =
    Input.hooks.useFileInput();
  const { value: groupIntroductionValue, onChange: onChangeGroupIntroductionValue } =
    Input.hooks.useInput("");
  const groupTypes = useGroupTypes();
  const navigate = useNavigate();
  const [submitLoading, setSubmitLoading] = useState(false);
  const [completeModalState, setCompleteModalState] = useState(false);

  const {
    PopupSelect: GroupTypePopupSelect,
    activeIds: activeGroupTypeIds,
    propsToInject: propsToInjectFirst,
  } = usePopupSelectV2();

  const groupLocations = useGroupLocation();

  const { PopupLocation, propsToInject, activeChildId, activeParentId } = usePopupLocation();

  const [group, setGroup] = useRecoilState(recoilStates.group);

  return (
    <>
      <Container className="page-pd d-flex fd-c gap-3 flex-1 jc-sb">
        <Input
          label="단체명"
          isEssential={true}
          attr={{ value: groupNameValue, onChange: onChangeGroupName }}
        />

        <GroupTypePopupSelect
          items={groupTypes ?? []}
          placeholder={"선택해주세요"}
          label={"단체성격"}
          isEssential={true}
          {...propsToInjectFirst}
        />
        {groupLocations && (
          <PopupLocation
            {...propsToInject}
            items={groupLocations}
            label="활동지역"
            isEssential={true}
            placeholder="선택해주세요"
          />
        )}
        <Input
          label="신청자(이름)"
          isEssential={true}
          attr={{ value: proposerNameValue, onChange: onChangeProposerName }}
        />
        {/*TODO 숫자 타입만들어가게만들기 대시 안들어가게*/}
        <Input
          label="대표 연락처"
          isEssential={true}
          attr={{
            type: "number",
            value: contactNumberValue,
            onChange: onChangeContactNumberValue,
          }}
        />
        <Input
          label="단체 카페/밴드 주소"
          isEssential={false}
          attr={{ value: groupSnsUrlValue, onChange: onChangeGroupSnsUrlValue }}
        />
        <Input
          label="단체 소개"
          isEssential={false}
          attr={{ value: groupIntroductionValue, onChange: onChangeGroupIntroductionValue }}
        />
        <Input
          label="단체 사진(로고)"
          fileName={fileName}
          imgUrl={previewTempUrl ?? ""}
          attr={{
            type: "file",
            onChange: (e) => {
              handleFileInput(e);
            },
          }}
        />
        <DoubleButton
          nagativeMargin={true}
          left={{
            name: "취소",
            themeColor: "lightGray",
            onClick: () => {
              navigate(-1);
            },
            widthRatio: 1,
          }}
          right={{
            name: "다음",
            themeColor: "green",
            onClick: () => {
              (async () => {
                const submitData = {
                  // teeshotId: user.id,
                  groupNameValue: groupNameValue?.trim(),
                  proposerNameValue: proposerNameValue?.trim(),
                  contactNumberValue: contactNumberValue?.trim(),
                  groupSnsUrlValue: groupSnsUrlValue?.trim(),
                  groupIntroductionValue: groupIntroductionValue?.trim(),
                  activeGroupTypeId: activeGroupTypeIds?.[0],
                  activeDistrictId: (() => {
                    if (!activeParentId) {
                      return null;
                    }
                    return `${activeParentId}${activeChildId || "000"}`;
                  })(),
                  groupImgBase64: base64,
                };
                if (
                  !submitData.groupNameValue ||
                  !submitData.proposerNameValue ||
                  !submitData.contactNumberValue ||
                  !activeGroupTypeIds?.[0] ||
                  !submitData.activeDistrictId
                ) {
                  alert("필수 입력값이 없습니다.");
                  return;
                }
                setSubmitLoading(true);

                try {
                  const res = await createTeeshotAxiosInstance().post("/yearBooking/group", {
                    submitData: submitData,
                  });
                  if (res.data.success === false) {
                    throw new Error();
                  }
                  const groupResult = await createTeeshotAxiosInstance().get("/yearBooking/group");
                  const groupResultData = groupResult?.data?.data;

                  if (!groupResultData?.group || !groupResultData?.members) {
                    alert("그룹정보 조회오류");
                    window.location.reload();
                    return;
                  }
                  setGroup({
                    ...groupResult.data.data.group,
                    members: groupResult.data.data.members,
                  });
                } catch (e) {
                  alert("단체 생성 실패");
                  navigate("/");
                } finally {
                  setSubmitLoading(false);
                }
                setCompleteModalState(true);
              })();
            },
            widthRatio: 1,
          }}
        />
      </Container>
      {completeModalState && (
        <Modal
          content={
            <div className="pd-3 d-flex fd-c jc-c gap-1 flex-1">
              <p>단체등록이 완료되었습니다.</p>
              <p>멤버를 등록하고 연간부킹을 신청하세요.</p>
            </div>
          }
          setModalState={setCompleteModalState}
          onConfirm={() => {
            navigate(`/year-booking`);
          }}
        />
      )}
      {(submitLoading || incodeLoading) && <SreenCoverLoading />}
    </>
  );
}

const Container = styled.div``;
