import { RecoilRoot } from "recoil";
import Router from "./Router";
import GlobalStyle from "./styles/GlobalStyles";
import InitRecoil from "./recoil/InitRecoil";
import { Meta } from "./components";

function App() {
  return (
    <>
      <Meta />
      <RecoilRoot>
        <InitRecoil />
        <GlobalStyle />

        <Router />
      </RecoilRoot>
    </>
  );
}

export default App;
