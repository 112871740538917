import React, { useRef, useState } from "react";
import SignatureCanvas from "react-signature-canvas";
import styled from "styled-components";
import Button from "../Button";
function SignModalContent(props: {
  setSignBase64: React.Dispatch<React.SetStateAction<string | null>>;
}) {
  const ref = useRef<SignatureCanvas>(null);

  return (
    <Container>
      <div>서명</div>
      <span className="sign-bg">
        <SignatureCanvas
          penColor="black"
          backgroundColor="transparent"
          ref={ref}
          canvasProps={{
            width: 250,
            height: 250,
            className: "sigCanvas",
          }}
          onEnd={(e) => {
            const dd = ref?.current?.toDataURL();
            props.setSignBase64(dd as string);
          }}
        />
      </span>

      <Button
        className="mt-2 mb-2 pd-2"
        $colorTheme="black"
        $round
        onClick={() => {
          props.setSignBase64(null);
          ref.current?.clear();
        }}
      >
        서명 지우기
      </Button>
    </Container>
  );
}

export default SignModalContent;
const Container = styled.div`
  padding: 10px;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .sign-bg {
    background-color: #f2f2f2;
    border: 1px solid gray;
  }
`;
