import React, { ReactNode, useEffect } from "react";
import { recoilStates } from "./recoilStates";
import { useRecoilState } from "recoil";
import { sleep } from "src/utils";
import { createTeeshotAxiosInstance } from "src/http";

export default function InitRecoil() {
  const [group, setGroup] = useRecoilState(recoilStates.group);
  const [_2, setInit] = useRecoilState(recoilStates.init);
  const [user, setUser] = useRecoilState(recoilStates.user);
  const [_4, setYearBooking] = useRecoilState(recoilStates.yearBooking);
  const [_5, setBookingApplication] = useRecoilState(recoilStates.bookingApplication);

  useEffect(() => {
    (async () => {
      if (!user.id) {
        return;
      }
      // const groupRes = await axiosTeeshotInstance.get("/yearBooking/group");
      const groupRes = await createTeeshotAxiosInstance().get("/yearBooking/group");
      if (groupRes.data.success === false) {
        alert("요청실패 group");
        return;
      }
      const groupData = groupRes?.data?.data;
      setGroup({ ...groupData.group, members: groupData.members });
      setYearBooking({
        bookings: [],
      });

      const yearBookingRes = await createTeeshotAxiosInstance().get("/yearBooking/booking");
      if (yearBookingRes?.data?.data) {
        setBookingApplication(yearBookingRes?.data?.data);
      }
      setInit({ isInit: true });
    })();
  }, [user?.id]);

  return <></>;
}
