import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { selector, useRecoilState, useRecoilValue } from "recoil";
import {
  Button,
  DoubleButton,
  Input,
  Label,
  Loading,
  SreenCoverLoading,
  usePopupPage,
} from "src/components";
import { ApplyPaperPopupContents } from "src/components/PopupPageContents";
import { createTeeshotAxiosInstance } from "src/http";
import { recoilStates } from "src/recoil/recoilStates";
import { caddyOptionData } from "src/staticData";
import { sleep } from "src/utils";
import styled from "styled-components";

type Sc = {
  months: { name: string }[];
  weeks: { name: string }[];
  days: { name: string }[];
  time: { name: string };
};
type RegisterDataType = {
  submitUser: {
    id: number;
    name: string;
    phone_number: string;
  };
  groupName: string;
  location: string;
  clubs: { preference: number; name: string }[];
  teamCount: { count: number };
  schedule: {
    first: Sc;
    second?: Sc;
  };
  playReq?: {
    caddyOpt?: { name: string };
    caddyCount?: number;
    cart5?: { count: number };
    cart2?: { count: number };
  };
  playType?: {
    name: string;
  };
  teeType?: {
    name: string;
  };
  etcReq?: { name: string }[];
  payment?: {
    green?: { id: number };
    cart?: { id: number };
    caddy?: { id: number };
    snackBar: { id: number };
    meal: { id: number };
  };
  addReq?: string;
};

//TODO 헤더버튼 클릭기능
export default function YearGroupRegisterResult() {
  const [isRegisterDateLoading, setIsRegisterDateLoading] = useState(true);
  const [registerData, setRegisterData] = useState<RegisterDataType>();
  const navigate = useNavigate();
  const [group] = useRecoilState(recoilStates.group);
  const [bookingApplication] = useRecoilState(recoilStates.bookingApplication);
  const [isCoverLoading, setIsCoverLoading] = useState(false);
  useEffect(() => {
    if (!bookingApplication?.id) {
      alert("부킹신청x");
      navigate("/");
    }
  }, []);

  const { PopupComponent: ApplyPopupComp, openPopup: openApplyPopupComp } = usePopupPage("apply");

  return (
    <>
      <div className="d-flex fd-c flex-1">
        <div className="d-flex fd-c flex-1 page-pd">
          <div className="d-flex fd-c flex-1 gap-3">
            <div className="">
              <Input
                label="단체명"
                boldLabel
                attr={{ readOnly: true, value: group?.name, className: "bold" }}
              />
            </div>
            <div className="d-flex fd-c gap-2">
              <Label className="bold" content={"희망 골프장"} />
              {bookingApplication?.clubRels.map((c) => {
                return (
                  <div className="d-flex fd-c gap-1">
                    <div className="d-flex gap-1" key={c.name}>
                      <Input
                        attr={{
                          readOnly: true,
                          value: c.preference,
                          style: { width: 30, textAlign: "center" },
                        }}
                      />
                      <Input
                        boxClassName="flex-1"
                        attr={{ readOnly: true, value: c.name, style: { flex: 1 } }}
                      />
                      {/* <Button
                        // className="as-c"
                        $round
                        $colorTheme="black"
                        // $buttonSize="md"
                        onClick={() => {
                          openApplyPopupComp();
                        }}
                      >
                        신청서 보기
                      </Button> */}
                    </div>
                  </div>
                );
              })}
            </div>
            <div>
              <Label className="bold" content={"참여 팀수"} />
              <div className="ml-2">{bookingApplication?.team_amount} 팀</div>
            </div>
            <div>
              <Label className="bold" content={"희망 월, 일자"} />
              <div className="ml-2">
                <div className="">
                  {bookingApplication?.hopeDates?.map((hd) => {
                    // hd.preference;
                    return (
                      <div key={`hd-${hd.id}`}>
                        <div>{hd.preference} 지망</div>
                        <div className="ml-2">
                          {JSON.parse(hd?.month_arr_json_str ?? [])
                            .map((m: number) => `${m}월`)
                            .join(", ")}
                        </div>
                        <div className="ml-2">
                          {JSON.parse(hd?.week_arr_json_str ?? [])
                            .map((w: number) => `${w}주차`)
                            .join(", ")}
                        </div>
                        <div className="ml-2">
                          {JSON.parse(hd?.day_arr_json_str ?? [])
                            .map((d: string) => `${d}요일`)
                            .join(", ")}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
            <div>
              <Label className="bold" content={"경기요청 사항"} />
              <div className="ml-2">
                <div>
                  {bookingApplication?.caddy_option_id ? (
                    <>
                      캐디옵션:{" "}
                      {
                        caddyOptionData.find(
                          (cod) => cod.id === bookingApplication?.caddy_option_id
                        )?.name
                      }
                    </>
                  ) : (
                    <></>
                  )}
                </div>
                <div>
                  {bookingApplication?.caddy_count_per_team ? (
                    <>팀당 캐디: {bookingApplication?.caddy_count_per_team} 명</>
                  ) : (
                    <></>
                  )}
                </div>
                <div>
                  {bookingApplication?.seat5_cart_count ? (
                    <>5인승 카트: {bookingApplication?.seat5_cart_count} 대</>
                  ) : (
                    <></>
                  )}
                </div>
                <div>
                  {bookingApplication?.seat2_cart_count ? (
                    <>2인승 카트: {bookingApplication?.seat2_cart_count} 대</>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>
            <div className="d-flex fd-c gap-2">
              <div className="flex-1">
                <Label className="bold " content=" 신청자(이름)" />
                <Input
                  attr={{
                    readOnly: true,
                    value: group?.members?.find(
                      (m) => m?.id === bookingApplication?.applying_member_id
                    )?.name,
                  }}
                />
              </div>
              <div className="flex-2">
                <Label className="bold" content=" 연락처" />
                <Input
                  attr={{
                    readOnly: true,
                    value: group?.members?.find(
                      (m) => m?.id === bookingApplication?.applying_member_id
                    )?.phone_number,
                  }}
                />
              </div>
            </div>
            <ApplyPopupComp type="x" headerMsg="신청서" content={<ApplyPaperPopupContents />} />

            <Button
              $round
              $colorTheme="red"
              $buttonSize="md"
              onClick={() => {
                if (window.confirm("정말로 부킹신청을 취소하시겠습니까?")) {
                  console.log(" 취소 api:");
                  (async () => {
                    try {
                      setIsCoverLoading(true);
                      await createTeeshotAxiosInstance().post("/yearBooking/booking/user-cancel", {
                        bookingId: bookingApplication?.id,
                      });
                    } catch (e) {
                      console.log("e :", e);
                      alert("취소요청 실패");
                    } finally {
                      navigate("/");
                      window.location.reload();
                      setIsCoverLoading(false);
                    }
                  })();
                  return;
                }
              }}
            >
              부킹신청 취소하기
            </Button>
          </div>
        </div>
        <DoubleButton
          left={{
            name: "닫기",
            themeColor: "gray",
            onClick: () => {
              navigate(-1);
            },
          }}
          right={{
            name: "수정",
            themeColor: "black",
            onClick: () => {
              alert("준비중");
              return;
              // navigate(`/year-booking/group-manage`);
            },
          }}
        />
      </div>
      {isCoverLoading && <SreenCoverLoading />}
    </>
  );
}

const Container = styled.div``;
