import { useCallback, useEffect, useMemo, useState } from "react";
import { Divider } from "src/components";
import styled from "styled-components";

const arr = new Array(30).fill(1);
export default function My() {
  const [count, setCount] = useState(1);
  const fn1 = () => {
    return 33;
  };
  // const handleChange = useCallback((newState: any) => {
  //   setCount(newState);
  // }, []);

  const zzzz = useMemo(() => 1, []);

  return (
    <Container className="ffff">
      <div>{count}</div>
      <button
        onClick={() => {
          setCount((c) => c + 1);
        }}
      >
        d
      </button>
      {/* <div className="inner">
        {arr.map((x) => {
          return <div className="cc">{x}</div>;
        })}
      </div> */}
      <Chil zz={fn1} />
      <Divider />
      <div>ddd</div>
    </Container>
  );
}

const Container = styled.div`
  /* flex: 1;
  min-height: 0;
  height: 100%;

  overflow: hidden;

  .inner {
    height: 100%;
    overflow: auto;
    background-color: coral;
    .cc {
      margin: 5px;
      background: #000;
    }
  } */
`;

const Chil = (props: { zz: any }) => {
  console.log(props.zz);

  useEffect(() => {
    console.log(" 나: load");
  }, []);

  return <div>나나</div>;
};
