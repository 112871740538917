import React, { ReactNode, useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import { VscTriangleDown } from "react-icons/vsc";
import Label from "./Label";
import Modal from "./Modal";
import { FaCheck } from "react-icons/fa";
import { colors } from "src/styles/theme";
import { useStopBackScroll } from "src/hooks";

export default function usePopupSelect(args: {
  items: { id: number; name: string }[];
  placeholder?: string;
  label?: string;
  boldLabel?: boolean;
  isEssential?: boolean;
  defaultSelectIds?: number[];
  multiple?: boolean;
  onSubmit?: (activeObejcts: any[]) => any;
}) {
  const [activeIds, setActiveIds] = useState<number[]>(args.defaultSelectIds ?? []);
  const [isPopupShown, setIsPopupShown] = useState(false);
  const reset = () => {
    setActiveIds([]);
  };
  useEffect(() => {
    if (args.defaultSelectIds?.[0]) {
      setActiveIds([args.defaultSelectIds?.[0]]);
    }
  }, [args.defaultSelectIds?.[0]]);

  function PopupSelect() {
    return (
      <>
        <Container className="" onClick={() => setIsPopupShown(true)}>
          {args.label && (
            <Label
              content={args.label}
              isEssential={args.isEssential}
              className={args.boldLabel ? "bold" : ""}
            />
          )}
          <div className="content-main input-box-type d-flex jc-sb ai-c">
            {activeIds?.length < 1 ? (
              <>
                <div className="showing">{args.placeholder}</div>
              </>
            ) : (
              <>
                {args.multiple ? (
                  <>
                    {args.items
                      .filter((item) => activeIds.includes(item.id))
                      .map((i) => i.name)
                      .join(", ")}
                  </>
                ) : (
                  <>
                    <div className="showing">
                      {args.items.find((i) => i.id === activeIds[0])?.name}
                    </div>
                  </>
                )}
              </>
            )}
            <VscTriangleDown />
          </div>
        </Container>
        {isPopupShown && (
          <Inner>
            <Modal
              setModalState={setIsPopupShown}
              onConfirm={() => {
                if (args.onSubmit) {
                  args.onSubmit(
                    args.items.filter((item) => {
                      return activeIds.some((id) => id === item.id);
                    })
                  );
                }
              }}
              content={
                <ModalContentContainer>
                  <h4>{args.label}</h4>
                  <ul>
                    {args.items.map((item) => {
                      return (
                        <li
                          onClick={() => {
                            if (activeIds.includes(item.id)) {
                              setActiveIds((aIds) => {
                                return aIds.filter((aid) => aid !== item.id);
                              });
                              return;
                            }
                            if (args.multiple) {
                              setActiveIds((aids) => [...aids, item.id]);
                              return;
                            }
                            setActiveIds([item.id]);
                          }}
                          key={item.id}
                          className={activeIds.includes(item.id) ? "active-true" : "active-false"}
                        >
                          <FaCheck />
                          <div>{item.name}</div>
                        </li>
                      );
                    })}
                  </ul>
                </ModalContentContainer>
              }
            ></Modal>
          </Inner>
        )}
      </>
    );
  }

  return { PopupSelect, activeIds, reset };
}

export function usePopupSelectV2(args?: { defaultSelectIds?: number[] }) {
  const [isPopupShown, setIsPopupShown] = useState(false);
  const [activeIds, setActiveIds] = useState<number[]>();

  const propsToInject = {
    activeIds,
    setActiveIds,
    isPopupShown,
    setIsPopupShown,
  };

  useEffect(() => {
    if (args?.defaultSelectIds?.[0]) {
      setActiveIds([args?.defaultSelectIds?.[0]]);
    }
  }, [args?.defaultSelectIds?.[0]]);

  const PopupSelect = useCallback(
    (props: {
      items: { id: number; name: string }[];
      placeholder?: string;
      label?: string;
      boldLabel?: boolean;
      isEssential?: boolean;
      multiple?: boolean;
      onSubmit?: (activeObejcts: any[]) => any;
      activeIds: any;
      setActiveIds: any;
    }) => {
      return (
        <>
          <Container className="" onClick={() => setIsPopupShown(true)}>
            {props.label && (
              <Label
                content={props.label}
                isEssential={props.isEssential}
                className={props.boldLabel ? "bold" : ""}
              />
            )}
            <div className="content-main input-box-type d-flex jc-sb ai-c">
              {(props.activeIds?.length || 0) < 1 ? (
                <>
                  <div className="showing">{props.placeholder}</div>
                </>
              ) : (
                <>
                  {props.multiple ? (
                    <>
                      {props.items
                        .filter((item) => props.activeIds?.includes(item.id))
                        .map((i) => i.name)
                        .join(", ")}
                    </>
                  ) : (
                    <>
                      <div className="showing">
                        {/* {zzzz} */}
                        {props.items.find((i) => i.id === props.activeIds?.[0])?.name}
                      </div>
                    </>
                  )}
                </>
              )}
              <VscTriangleDown />
            </div>
          </Container>
          {isPopupShown && (
            <Inner>
              <Modal
                setModalState={setIsPopupShown}
                onConfirm={() => {
                  if (props.onSubmit) {
                    props.onSubmit(
                      props.items.filter((item) => {
                        return props.activeIds?.some((id: any) => id === item.id);
                      })
                    );
                  }
                }}
                content={
                  <ModalContentContainer>
                    <h4>{props.label}</h4>
                    <ul>
                      {props.items.map((item) => {
                        return (
                          <li
                            onClick={() => {
                              if (props.activeIds?.includes(item.id)) {
                                props?.setActiveIds((aIds: any) => {
                                  return aIds.filter((aid: any) => aid !== item.id);
                                });
                                return;
                              }
                              if (props.multiple) {
                                props.setActiveIds((aids: any) => [...aids, item.id]);
                                return;
                              }
                              props.setActiveIds([item.id]);
                            }}
                            key={item.id}
                            className={
                              props.activeIds?.includes(item.id) ? "active-true" : "active-false"
                            }
                          >
                            <FaCheck />
                            <div>{item.name}</div>
                          </li>
                        );
                      })}
                    </ul>
                  </ModalContentContainer>
                }
              ></Modal>
            </Inner>
          )}
        </>
      );
    },
    [isPopupShown]
  );

  return { PopupSelect, propsToInject, activeIds };
}

const Container = styled.div`
  cursor: pointer;
  .content-main {
    padding: 5px;
  }
  .showing {
    font-size: 12px;
  }
`;
const Inner = (props: { children: ReactNode }) => {
  useStopBackScroll();
  return <>{props.children}</>;
};

const ModalContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  /* gap: 10px; */
  h4 {
    padding: 10px;
  }

  ul {
    max-height: 70vh;
    overflow: auto;
    li {
      cursor: pointer;
      padding: 10px;
      border: 1px solid black;
      display: flex;
      align-items: center;
      gap: 10px;
      svg {
        color: ${colors.gray.second};
      }
      &.active-true {
        svg {
          color: ${colors.green.first};
        }
      }
    }
  }
`;
