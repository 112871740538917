import styled from "styled-components";
import Portal from "./Portal";
import { ComponentProps, ReactNode, useCallback, useEffect, useState } from "react";
import { colors } from "src/styles/theme";
import { IoMdClose } from "react-icons/io";
import { IoArrowBack } from "react-icons/io5";
import Button from "./Button";
import { useLocation, useNavigate } from "react-router-dom";
import { FaArrowLeft } from "react-icons/fa6";

export default function usePopupPage(key: string) {
  const [isOpen, setIsOpen] = useState(false);
  const lc = useLocation();
  const { pathname, search } = lc;
  const navigate = useNavigate();

  useEffect(() => {
    if (search.includes(`${key}=`)) {
      setIsOpen(true);
    } else {
      setIsOpen(false);
    }
  }, [search]);

  const openPopup = useCallback(() => {
    navigate(`${pathname}${search}${search.includes("?") ? `&` : "?"}${key}=1`);
  }, [pathname, search, key]);

  // TODO 팝업 여러개 열때 리렌더링 버그 해결

  const closePopup = () => {
    navigate(-1);
  };

  function PopupPage(props: {
    headerMsg?: string;
    type?: "x" | "back";
    backgroundColor?: string;
    closeBtnColorTheme?: ComponentProps<typeof Button>["$colorTheme"];
    zIndex?: number;
    content?: ReactNode;
    children?: ReactNode;
  }) {
    useEffect(() => {
      const body = document.querySelector("body");
      if (body) {
        body.style.overflow = "hidden";
      }

      return () => {
        if (body) {
          body.style.overflow = "auto";
        }
      };
    }, []);

    return (
      <Portal isRootPortal>
        <Container className="popup-page d-flex fd-c" $backGroundColor={props.backgroundColor}>
          <header className="pp-header">
            <span>
              {props.type === "back" && (
                <Button
                  $colorTheme={props?.closeBtnColorTheme ?? "white"}
                  onClick={() => {
                    closePopup();
                  }}
                >
                  <FaArrowLeft size="20px" />
                </Button>
              )}
            </span>
            <div>{props.headerMsg}</div>
            <span>
              {props.type === "x" && (
                <Button
                  $colorTheme={props?.closeBtnColorTheme ?? "white"}
                  onClick={() => {
                    closePopup();
                  }}
                >
                  <IoMdClose fontSize={30} />
                </Button>
              )}
            </span>
          </header>
          {/* <div className="popup-page-content">{props.content ?? props.content}</div> */}
          <div className="popup-page-content">{props.content}</div>
          {}
        </Container>
      </Portal>
    );
  }

  function Render(props: ComponentProps<typeof PopupPage>) {
    if (isOpen) {
    }
    return isOpen ? <PopupPage {...props} /> : <></>;
  }
  return { openPopup, closePopup, PopupComponent: Render };
}

const Bg = styled.div<{ $backGroundColor?: string; $zIndex?: number }>`
  z-index: ${(p) => (p.$zIndex ? p.$zIndex : 100)};
  position: fixed;
  left: 0;
  top: 0;
  min-height: 100dvh;
  min-width: 100vw;
  display: flex;
  background-color: ${(p) => (p.$backGroundColor ? p.$backGroundColor : "white")};
  justify-content: center;
`;
const Container = styled.div<{ $backGroundColor?: string }>`
  position: fixed;
  left: 50%;
  top: 0;
  width: 100%;
  min-height: 100dvh;
  height: 100%;
  transform: translateX(-50%);
  max-width: 800px;
  background-color: ${(p) => (p.$backGroundColor ? p.$backGroundColor : "white")};
  display: flex;
  flex-direction: column;
  header.pp-header {
    padding: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 18px;
    font-weight: bold;
    border-bottom: 1px solid ${colors.gray.second};
    height: 55px;
    > :first-child,
    > :last-child {
      min-width: 30px;
    }
    span {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .popup-page-content {
    flex: 1;
    max-height: calc(100% - 55px);
    display: flex;
    flex-direction: column;
  }
`;
