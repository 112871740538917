import { useRecoilState } from "recoil";
import ContentBox from "../ContentBox";
import { recoilStates } from "src/recoil/recoilStates";
import Button from "../Button";
import InvitationCodeForm from "./InvitationCodeForm";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import Stepper from "../Stepper";

export default function NoYearGroupContent(props: { isRejectedByAllClubs?: boolean }) {
  const [group] = useRecoilState(recoilStates.group);
  const hasGroup = Boolean(group.id);

  const navigate = useNavigate();
  return (
    <Container className="d-flex fd-c gap-2 flex-1 jc-sb">
      {hasGroup ? (
        <Stepper
          className="page-pd-negative-side page-pd-negative-top"
          currentIdx={props.isRejectedByAllClubs ? 2 : 0}
          title={props.isRejectedByAllClubs ? "골프장 신청 반려" : "부킹 신청하세요~"}
          steps={[
            { label: <>단체등록</> },
            { label: "부킹 신청" },
            { label: "조건 협의", isError: props.isRejectedByAllClubs ? true : false },
            { label: "부킹 확정" },
            {
              label: (
                <div className="d-flex fd-c ai-c">
                  <div>보증금</div>
                  <div>확인</div>
                </div>
              ),
            },
            { label: "멤버 등록" },
          ]}
        />
      ) : null}
      <div className="d-flex fd-c gap-2">
        <ContentBox
          header={<div className="as-c">연간 부킹 이용 안내</div>}
          // content={"이용 안내문.."}
          content={
            <>
              <img
                // src="https://api.v2.teeshot.co.kr/event/booking-test.jpg"
                src="https://api.v2.teeshot.co.kr/event/resource/images/year-booking-desc.jpg"
                alt=""
              />
            </>
          }
        />
        <Button
          $colorTheme="lightGreen"
          $round={true}
          $buttonSize="md"
          onClick={() =>
            navigate(hasGroup ? "/year-booking/booking-register" : "/year-booking/group-register")
          }
        >
          {hasGroup ? "연간 부킹 신청" : "단체 생성"}
        </Button>
      </div>
      {/* {!hasGroup && <InvitationCodeForm />} */}
    </Container>
  );
}

const Container = styled.div`
  .group-top {
    display: flex;
    align-items: center;
    gap: 10px;
    .group-img {
      width: 50px;
      height: 50px;
      border-radius: 999px;
    }
  }
`;
