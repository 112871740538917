import { Navigate, Route, Routes, BrowserRouter, Outlet } from "react-router-dom";
import * as P from "./pages";
import { Layout, SreenCoverLoading } from "./components";
import { useAuth } from "./hooks";
export default function Router() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/kakao-load" element={<P.KakaoLoad />} />

        <Route element={<PrivateRoutes />}>
          <Route path="/" element={<Navigate to="/year-booking" />} />
          <Route element={<Layout.SimpleLayout />}>
            <Route path="/year-booking/group-register" element={<P.YearGroupRegister />} />
            <Route path="/year-booking/booking-register" element={<P.YearBookingRegister />} />
            <Route path="/year-booking/group-manage" element={<P.YearGroupManage />} />
            <Route path="/year-booking/group-members" element={<P.YearGroupMemebers />} />
            <Route
              path="/year-booking/group-members/register-all"
              element={<P.YearGroupMembersRegisterAll />}
            />
            <Route path="/year-booking/group-members/list" element={<P.YearGroupMembersList />} />
            <Route path="/year-booking/team-register" element={<P.YearGroupTeamRegister />} />
          </Route>
          <Route element={<Layout.MainLayout />}>
            <Route
              path="/year-booking/group-register/result"
              element={<P.YearGroupRegisterResult />}
            />
            <Route element={<Layout.TopTabLayout />}>
              <Route path="/year-booking" element={<P.YearBooking />} />
              <Route path="/event-booking" element={<P.EventBooking />} />
            </Route>

            <Route path="*" element={<Navigate to="/" />} />
          </Route>
        </Route>
        <Route path="/my" element={<P.My />} />
      </Routes>
    </BrowserRouter>
  );
}

const PrivateRoutes = () => {
  const { isLoading: isAuthLoading, user, isInvalidUser } = useAuth();
  if (isAuthLoading) {
    return (
      <>
        <SreenCoverLoading />
      </>
    );
  }
  if (isInvalidUser) {
    return <>유저정보 없음</>;
  }
  return <Outlet />;
};
